import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";

const ModalUseUserAPIKey = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState({});
  const cancelButtonRef = useRef(null);
  const [userAPIKey, setUserAPIKey] = useState("");
  useImperativeHandle(
    ref,
    () => {
      return {
        open(opt) {
          setOption(opt);
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
      };
    },
    []
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-[80%] items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <XMarkIcon
                  className={
                    "h-6 w-6 m-2 hover:text-sky-400 cursor-pointer right-0 absolute text-zinc-500"
                  }
                  aria-hidden="true"
                  onClick={() => setOpen(false)}
                />
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left cursor-default w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {option.title}
                      </Dialog.Title>
                      <div className="flex items-center space-x-2 w-full mt-2">
                        <input
                          type="text"
                          id="apiKey"
                          autoFocus={true}
                          defaultValue={""}
                          onInput={(e) => setUserAPIKey(e.target.value)}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              option.handleUseUserAPIKey(userAPIKey);
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-2 focus:ring-sky-300 block w-full p-2.5 "
                          placeholder="Enter your API key"
                          required
                        />

                        <PlusIcon
                          onClick={() => option.handleUseUserAPIKey(userAPIKey)}
                          className="cursor-pointer text-sky-400  w-6 h-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}, []);
export default ModalUseUserAPIKey;
