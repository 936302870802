import React, { useContext, useState } from "react";
import { Divider } from "@mui/material";
import PricingBox from "./PricingBox";
import { FEATURES } from "../../common/global";
import { AuthContext } from "../../../context/AuthContext";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { activateLicenseKey } from "../../../api/service";

const SubscriptionPanel = () => {
  const { user } = useContext(AuthContext);
  const [licenseKeyValue, setLicenseKeyValue] = useState("");
  const queryClient = useQueryClient();
  const activateLicenseKeyMutation = useMutation(
    (licenseKey) => {
      return activateLicenseKey(licenseKey);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["profile"],
          exact: true,
        });
        toast.success("Activate license key succesfully!");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const handleActivateLicenseKey = async (licenseKey) => {
    activateLicenseKeyMutation.mutate({ licenseKey: licenseKey });
  };

  return (
    <>
      <div>
        <div className="items-center text-center flex">
          <span className="text-xl font-semibold text-sky-400 m-auto cursor-default">
            Subscription
          </span>
        </div>

        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      </div>
      <div className="p-2 overflow-auto flex flex-row max-h-3/4 min-h-1/2 cursor-default space-x-4">
        {Object.entries(FEATURES).map(([k, v]) => (
          <PricingBox
            key={k}
            name={k}
            description={v.description}
            price={v.monthly}
            features={v.features}
            ctaText={v.ctaText}
            currentPlan={user ? user.subscription : "standard"}
            user={user ?? null}
          />
        ))}
      </div>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <div className="flex text-left ml-4 font-medium justify-between">
        <div className="flex flex-col w-full">
          <span className="font-semibold">License key</span>
          <div
            className={`w-full flex items-center space-x-2 p-1.5 rounded-lg ${
              !user && "blur-[1.5px] cursor-not-allowed"
            }`}
          >
            <input
              type={user?.subscription === "premium" ? "password" : "text"}
              id="licenseKey"
              disabled={!user || user?.subscription === "premium"}
              value={
                user?.subscription === "premium"
                  ? "************************"
                  : licenseKeyValue
              } // Masked value for premium users
              onInput={(e) => {
                if (user?.subscription !== "premium") {
                  setLicenseKeyValue(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && user?.subscription !== "premium") {
                  handleActivateLicenseKey(licenseKeyValue);
                }
              }}
              className={`bg-gray-50 border-solid border-[1.5px] outline-none focus:ring-2 focus:ring-sky-400
      border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 ${
        !user || user?.subscription === "premium"
          ? "cursor-not-allowed blur-[1px]"
          : "cursor-text"
      }`}
              placeholder="Put your license key here"
            />
            <PaperAirplaneIcon
              className="h-6 w-6 text-sky-400 hover:text-sky-500 cursor-pointer"
              onClick={() => {
                if (user?.subscription !== "premium") {
                  handleActivateLicenseKey(licenseKeyValue);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscriptionPanel;
