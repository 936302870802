import Chart from "react-apexcharts";
import { memo, useEffect, useState } from "react";

const CategoryChart = ({ data }) => {
  const [chartKey, setChartKey] = useState(0);
  useEffect(() => {
    setChartKey((key) => key + 1); // increment the key to trigger a re-render
  }, [data]);
  const options = {
    chart: {
      width: 380,
      type: "pie",
      id: "chart-id",
    },
    colors: data.colors,
    series: data.series,
    labels: data.labels,
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title: {
      text: "Category Statistic",
      align: "left",
      margin: 10,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    noData: {
      text: "You haven't completed any sessions yet!",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#71717a",
        fontSize: "14px",
        fontWeight: 600,
      },
    },

    legend: {
      formatter: function (val, opts) {
        return [
          val,
          "(" +
            Math.round(opts.w.globals.seriesPercent[opts.seriesIndex] * 100) /
              100 +
            "%)",
        ];
      },

      fontWeight: "bold",
      fontSize: "12px",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: function (value) {
        return Math.round(value * 100) / 100 + "%";
      },
      style: {
        fontSize: "10px",
      },
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return (
            Math.floor(value / 60) +
            "h" +
            Math.round((value % 60) * 100) / 100 +
            "m"
          );
        },
      },
    },
  };
  return (
    <>
      <div id="categoryChart" className={"bg-gray-400/20 rounded-lg m-2 p-2"}>
        <Chart
          key={chartKey}
          options={options}
          series={data.series}
          type="pie"
          height={250}
          minWidth={380}
          maxWidth={400}
        />
      </div>
    </>
  );
};
export default memo(CategoryChart, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
