import React, { useRef, useEffect } from "react";

const TimerPlayerComponent = ({ audioUrl, play }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const handleAudioEnd = () => {
      audioRef.current.currentTime = 0;
      playAudio();
    };

    const playAudio = () => {
      if (audioRef.current && play.current) {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }
    };

    const checkAudioTime = () => {
      if (
        audioRef.current &&
        play.current &&
        audioRef.current.currentTime >= audioRef.current.duration - 1
      ) {
        // Check if remaining time is less than 1 second
        const remainingTime =
          audioRef.current.duration - audioRef.current.currentTime;
        // If remaining time is less than 1 second, reset the audio
        if (remainingTime <= 1) {
          audioRef.current.currentTime = 0;
        }
      }
    };
    // Add event listener for audio end
    audioRef.current.addEventListener("ended", handleAudioEnd);
    // Check audio time every second
    const checkTimeInterval = setInterval(() => {
      checkAudioTime();
    }, 1000);
    // Cleanup
    return () => {
      audioRef?.current?.removeEventListener("ended", handleAudioEnd);
      clearInterval(checkTimeInterval);
    };
  }, [play.current]);

  useEffect(() => {
    if (play.current) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    // return () => {
    //   console.log(audioRef);
    //   audioRef.current.pause();
    //   audioRef.current.currentTime = 0;
    // };
  }, [play, play.current]);

  return <audio ref={audioRef} src={audioUrl}></audio>;
};

export default TimerPlayerComponent;
