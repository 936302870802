import React from "react";

const VideoCall = ({ room, onJoinRoom }) => {
  return (
    <div className="bg-gray-400/20 flex flex-col text-black rounded-xl h-full p-4 m-2 space-y-4">
      <div className="bg-zinc-50 h-auto md:h-3/4 w-full rounded-lg flex flex-col justify-center items-center">
        <span className="font-semibold text-center">
          Room: {room?.roomName}
        </span>
        <div className="flex flex-row items-center justify-center mt-2">
          <div className="block h-2 w-2 rounded-full mr-1 bg-green-500"></div>
          <span className="text-zinc-500">
            {room?.participants?.length} users online
          </span>
        </div>
      </div>
      <button
        className="bg-sky-400 hover:bg-sky-500 w-fit self-center font-semibold rounded-xl py-2 px-4 text-white"
        onClick={() => onJoinRoom(room.roomId)}
      >
        Join room
      </button>
    </div>
  );
};

export default VideoCall;
