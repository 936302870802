import React, { useState } from "react";
import {
  encryptAndStoreData,
  retrieveAndDecryptData,
} from "../../common/encryptedStorage";
import { useContext } from "react";
import { SettingContext } from "../../../context/SettingContext";
import { Divider } from "@mui/material";
import { useEffect } from "react";
export const PomodoroSetting = () => {
  const storage = retrieveAndDecryptData("default");
  const settingInfo = useContext(SettingContext);
  const [pomodoro, setPomodoro] = useState(settingInfo.pomoMinute);
  const [shortBreak, setShortBreak] = useState(settingInfo.shortBreakMinute);
  const [longBreak, setLongBreak] = useState(settingInfo.longBreakMinute);
  const [isAutoStartBreak, setIsAutoStartBreak] = useState(
    settingInfo.isAutoStartBreak
  );
  const [isAutoStartPomo, setIsAutoStartPomo] = useState(
    settingInfo.isAutoStartPomo
  );
  const [isAlarmSoundOn, setIsAlarmSoundOn] = useState(
    settingInfo.isAlarmSoundOn
  );
  const [isTickingSoundOn, setIsTickingSoundOn] = useState(
    settingInfo.isTickingSoundOn
  );
  const [isPushNotificationOn, setIsPushNotificationOn] = useState(
    settingInfo.isPushNotificationOn
  );
  const [longBreakInterval, setLongBreakInterval] = useState(
    settingInfo.longBreakInterval
  );

  const [notificationLast, setNotificationLast] = useState(
    settingInfo.notifyLastMinutes
  );
  const [disabled, setDisabled] = useState(false);
  const checkValueChange = () => {
    if (settingInfo.pomoMinute !== pomodoro) {
      settingInfo.setPreviousValuePomo(settingInfo.pomoMinute);
      settingInfo.setPomoMinute(pomodoro);
      storage.pomoValue = pomodoro;
      // set other options to the current value
      settingInfo.setPreviousValueLong(settingInfo.longBreakMinute);
      settingInfo.setPreviousValueShort(settingInfo.shortBreakMinute);
    }
    if (settingInfo.shortBreakMinute !== shortBreak) {
      settingInfo.setPreviousValueShort(settingInfo.shortBreakMinute);
      settingInfo.setShortBreakMinute(shortBreak);
      storage.shortValue = shortBreak;
      // set other options to the current value
      // console.log("set short break");
      settingInfo.setPreviousValuePomo(settingInfo.pomoMinute);
      settingInfo.setPreviousValueLong(settingInfo.longBreakMinute);
    }
    if (settingInfo.longBreakMinute !== longBreak) {
      settingInfo.setPreviousValueLong(settingInfo.longBreakMinute);
      settingInfo.setLongBreakMinute(longBreak);
      storage.longValue = longBreak;
      // localStorage.setItem("default", JSON.stringify(storage));
      // set other options to the current value
      settingInfo.setPreviousValuePomo(settingInfo.pomoMinute);
      settingInfo.setPreviousValueShort(settingInfo.shortBreakMinute);
    }
    settingInfo.setSettingConfirm((prev) => !prev);
  };

  useEffect(() => {
    if (
      pomodoro <= 0 ||
      shortBreak <= 0 ||
      longBreak <= 0 ||
      longBreakInterval <= 0 ||
      notificationLast <= 0 ||
      isNaN(pomodoro) === true ||
      isNaN(shortBreak) === true ||
      isNaN(longBreak) === true ||
      isNaN(longBreakInterval) === true ||
      isNaN(notificationLast) === true
    ) {
      setDisabled(true);
    } else setDisabled(false);
  }, [pomodoro, shortBreak, longBreak, longBreakInterval, notificationLast]);
  const handleSubmitSetting = () => {
    console.log("Click change setting");
    checkValueChange();
    settingInfo.setNotifyLastMinutes(notificationLast);
    settingInfo.setLongBreakInterval(longBreakInterval);
    settingInfo.setIsAutoStartBreak(isAutoStartBreak);
    settingInfo.setIsAutoStartPomo(isAutoStartPomo);
    settingInfo.setIsAlarmSoundOn(isAlarmSoundOn);
    settingInfo.setIsTickingSoundOn(isTickingSoundOn);
    settingInfo.setIsPushNotificationOn(isPushNotificationOn);
    storage.notifyLastMinutes = notificationLast;
    storage.longBreakInterval = longBreakInterval;
    storage.isAutoStartBreak = isAutoStartBreak;
    storage.isAutoStartPomo = isAutoStartPomo;
    storage.isTickingSoundOn = isTickingSoundOn;
    storage.isAlarmSoundOn = isAlarmSoundOn;
    storage.isPushNotificationOn = isPushNotificationOn;
    encryptAndStoreData("default", storage);
    settingInfo.setShowSetting(false);
  };
  return (
    <>
      <div>
        <div className="items-center text-center flex">
          <span className="text-xl font-semibold text-sky-400 m-auto cursor-default">
            Pomodoro Setting
          </span>
        </div>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      </div>
      {/* timer */}
      <div className="p-2 overflow-auto flex flex-col h-full">
        <div className="">
          <span className="text-lg flex font-semibold cursor-default">
            Time (minutes)
          </span>
          <div className="flex text-left font-medium justify-between ">
            <div className="w-28">
              <label>Pomodoro</label>
              <input
                type="number"
                onInput={(e) => setPomodoro(parseInt(e.target.value))}
                className="rounded-md bg-gray-100 p-2 w-full outline-none focus:ring-2 focus:ring-sky-300"
                defaultValue={pomodoro}
              />
            </div>
            <div className="w-28">
              <label>Short Break</label>
              <input
                type="number"
                onInput={(e) => setShortBreak(parseInt(e.target.value))}
                className="rounded-md  bg-gray-100 p-2 w-full outline-none focus:ring-2 focus:ring-sky-300 "
                defaultValue={shortBreak}
              />
            </div>
            <div className="w-28">
              <label>Long Break</label>
              <input
                onInput={(e) => setLongBreak(parseInt(e.target.value))}
                type="number"
                className="rounded-md bg-gray-100 p-2 w-full outline-none focus:ring-2 focus:ring-sky-300"
                defaultValue={longBreak}
              />
            </div>
          </div>
        </div>
        {/* OPTIONS */}
        {/* auto break */}
        <div className="">
          <span className="text-lg flex font-semibold cursor-default mt-4 mb-4">
            Options
          </span>
          <div className="flex text-left font-medium justify-between cursor-default">
            <span>Auto start breaks?</span>
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                value={settingInfo.isAutoStartBreak}
                onChange={() => {
                  setIsAutoStartBreak((prev) => !prev);
                }}
                className="sr-only peer"
                defaultChecked={settingInfo.isAutoStartBreak}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-sky-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-400"></div>
            </label>
          </div>
          {/* Auto pomo */}
          <div className="flex text-left font-medium justify-between mt-4 mb-4 cursor-default">
            <span>Auto start pomos?</span>

            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                value={isAutoStartPomo}
                defaultChecked={isAutoStartPomo}
                onChange={() => {
                  setIsAutoStartPomo((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-sky-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-400"></div>{" "}
            </label>
          </div>

          <div className="flex text-left font-medium justify-between mt-4 mb-4 cursor-default">
            <span>Alarm sound</span>

            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                value={isAlarmSoundOn}
                defaultChecked={isAlarmSoundOn}
                onChange={() => {
                  setIsAlarmSoundOn((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-sky-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-400"></div>
            </label>
          </div>
          <div className="flex text-left font-medium justify-between mt-4 mb-4 cursor-default">
            <span>Ticking sound</span>
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                value={isTickingSoundOn}
                defaultChecked={isTickingSoundOn}
                onChange={() => {
                  setIsTickingSoundOn((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:peer-focus:ring-sky-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-400"></div>
            </label>
          </div>
          <div className="flex text-left font-medium justify-between mt-4 mb-4 cursor-default">
            <span>Push notification</span>
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                value={isPushNotificationOn}
                defaultChecked={isPushNotificationOn}
                onChange={() => {
                  setIsPushNotificationOn((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer  dark:peer-focus:ring-sky-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-400"></div>
            </label>
          </div>
          {/* Long break */}
          <div className="flex text-left font-medium justify-between items-center mt-4 mb-4 cursor-default">
            <span>Set Long break interval</span>
            <label className="inline-flex relative items-center cursor-pointer ">
              <input
                defaultValue={longBreakInterval}
                onInput={(e) => {
                  setLongBreakInterval(parseInt(e.target.value));
                }}
                type="number"
                className="rounded-md bg-gray-100 p-2 h-10 w-24 outline-none focus:ring-2 focus:ring-sky-300"
              />
            </label>
          </div>
          {/* <div className="flex text-left font-medium justify-between items-center  mt-4 mb-4">
            <span>Set Long break interval</span>
            <label className="inline-flex relative items-center cursor-pointer ">
              <input
                defaultValue={longBreakInterval}
                onInput={(e) => setLongBreakInterval(parseInt(e.target.value))}
                type="number"
                className="rounded-md bg-gray-100 p-2 h-10 w-24 outline-none focus:ring-2 focus:ring-sky-300"
              />
            </label>
          </div>
          <div className="flex text-left font-medium justify-between items-center  mt-4 mb-4">
            <span>Set Long break interval</span>
            <label className="inline-flex relative items-center cursor-pointer ">
              <input
                defaultValue={longBreakInterval}
                onInput={(e) => setLongBreakInterval(parseInt(e.target.value))}
                type="number"
                className="rounded-md bg-gray-100 p-2 h-10 w-24 outline-none focus:ring-2 focus:ring-sky-300"
              />
            </label>
          </div> */}

          <div className="flex text-left font-medium justify-between items-center mt-4 mb-4 cursor-default">
            <span>Notify when last minutes</span>
            <label className="inline-flex relative items-center cursor-pointer ">
              <input
                onInput={(e) => {
                  setNotificationLast(parseInt(e.target.value));
                }}
                disabled={isPushNotificationOn === false}
                defaultValue={notificationLast}
                type="number"
                className={
                  isPushNotificationOn === false
                    ? "rounded-md bg-gray-100 p-2 h-10 w-24 outline-none focus:ring-2 focus:ring-sky-300 blur-[0.8px] hover:cursor-not-allowed"
                    : "rounded-md bg-gray-100 p-2 h-10 w-24 outline-none focus:ring-2 focus:ring-sky-300"
                }
              />
            </label>
          </div>
        </div>
        <div
          className={
            disabled
              ? "bg-sky-400 rounded-lg ml-auto mt-auto w-20 h-8 bottom-0 text-gray-100 cursor-not-allowed" +
                "hover:text-white flex items-center justify-center right-0 " +
                "font-medium" +
                "text-base"
              : "bg-sky-400 rounded-lg w-20 h-8 bottom-0 ml-auto mt-auto cursor-pointer text-gray-100" +
                "transform hover:scale-105 transition duration-200 hover:text-white flex items-center justify-center font-medium text-base"
          }
        >
          <button
            onClick={handleSubmitSetting}
            disabled={disabled}
            className={
              disabled
                ? "cursor-not-allowed text-gray-100"
                : "cursor-pointer text-gray-100"
            }
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};
