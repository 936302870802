import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";

const WarningDialog = ({ text, screen, onClose }) => {
  let [isOpen, setIsOpen] = useState(true);
  let screenSize =
    screen === "sm"
      ? "sm:hidden block"
      : screen === "xl"
        ? "xl:hidden block"
        : screen === "md"
          ? "md:hidden block"
          : "lg:hidden block";
  const closeModal = () => {
    // setIsOpen(false);
    // we allow user to close this warning since they can still use other features in the app
    onClose();
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-[1000] ${screenSize}`}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-row items-center space-x-2">
                  <div className=" flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Warning
                  </Dialog.Title>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{text}</p>
                </div>

                <div className="mt-4">
                  {/* md screen above: allow to close this warning dialog */
                  /* under md screen: not allow to use app, so not display this close modal button*/}
                  {screen !== "md" && (
                    <button
                      type="button"
                      className="flex ml-auto text-sky-600 justify-center rounded-md border border-transparent bg-sky-100 px-4 py-2 text-sm font-medium hover:bg-sky-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default WarningDialog;
