import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import TaskForm from "./TaskForm";
import TaskItem from "./TaskItem";
import { SettingContext } from "../../context/SettingContext";
import { AuthContext } from "../../context/AuthContext";
import { PlusIcon } from "@heroicons/react/20/solid";
import { encryptAndStoreData } from "../common/encryptedStorage";

const useStyles = makeStyles(() => ({
  containerTasks: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid rgba(255,255,255,0.6)",
    paddingBottom: 14,
  },
  containerTasksButton: {
    position: "relative",
  },
  buttonTask: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    opacity: 0.9,
    background: "none rgba(255,255,255,0.2)",
    boxShadow: "none",
    marginLeft: 10,
    fontSize: 13,
    padding: 8,
    minWidth: "auto",
    border: "none",
    color: "black !important",
  },

  containerEstimation: {
    padding: "20px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 16,
    backgroundColor: "#7dd3fc",
  },
  editButton: {
    borderRadius: 4,
    opacity: 1,
    padding: "4px 0px",
    boxShadow: "rgb(0 0 0 / 15%) 0px 10px 20px, rgb(0 0 0 / 10%) 0px 3px 6px",
    display: "block",
    pointerEvents: "auto",
    position: "absolute",
    backgroundColor: "white",
    transform: "translateY(10px)",
    width: 200,
    right: 0,
    zIndex: 1,
  },
  editOptions: {
    color: "rgb(79,43,45)",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
    fontSize: 14,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(211,211,211)",
    },
  },

  estimationItem: {
    margin: "0px 8px",
    color: "rgba(255,255,255,0.7)",
  },
  estimationValue: {
    color: "white",
    fontWeight: 600,
    fontSize: 24,
    marginLeft: 4,
  },
}));
const TaskList = ({ tasks, setTasks }) => {
  const classes = useStyles();
  const settingInfo = useContext(SettingContext);
  const [finishAt, setFinishAt] = useState(null);
  const [totalPomo, setTotalPomo] = useState(null);
  const [act, setAct] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const editRef = useRef(null);
  const { user, isLogin } = useContext(AuthContext);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEdit(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(editRef);

  function handleAddTaskButton() {
    settingInfo.setShowInputTask((prev) => !prev);
  }

  const calculateFinishAt = (pomoTime, totalPomo, act) => {
    let today = new Date();
    let hour = today.getHours();
    let minute = today.getMinutes();
    let pomoHour = pomoTime / 60;
    let pomoMinute = pomoTime % 60;
    let longBreakInterval = settingInfo.longBreakInterval;
    let longBreakTimes = Math.floor(totalPomo / longBreakInterval);
    let longBreakMinutes = longBreakTimes * settingInfo.longBreakMinute;
    // overlappedTimes means that the long break time is overlapped with the short break time
    // and we prioritize the long break time, so short break times need to be subtracted
    let overlappedTimes = longBreakTimes > 0 ? longBreakTimes - 1 : 0;
    let shortBreakMinute =
      (totalPomo - 1 - overlappedTimes) * settingInfo.shortBreakMinute;
    // actCalculate is the total time of the act pomos and short break times
    // we need to subtract this time from the total time to get the finish time
    let actCalculate;
    // if totalPomo > 1 and act > 0, we need to subtract the short break time of the last pomo
    // because the last pomo doesn't have a short break time
    totalPomo > 1 && act > 0
      ? (actCalculate =
          act * settingInfo.pomoMinute +
          (act - 1) * settingInfo.shortBreakMinute)
      : (actCalculate =
          act * settingInfo.pomoMinute + act * settingInfo.shortBreakMinute);
    let totalHour = parseInt(hour) + pomoHour;
    // totalMinute is the total time of the pomo, short break, long break, and act
    let totalMinute =
      parseInt(minute) +
      pomoMinute +
      shortBreakMinute +
      longBreakMinutes -
      actCalculate;
    totalMinute = totalMinute < 0 ? totalMinute * -1 : totalMinute;

    // while totalMinute is greater than 60, we need to add 1 to the totalHour
    // and subtract 60 from the totalMinute
    while (totalMinute >= 60) {
      totalHour++;
      totalMinute = totalMinute - 60;
      if (totalHour >= 24) {
        totalHour = Math.floor(totalHour - 24);
      }
      if (totalHour < 10) {
        totalHour = "0" + totalHour;
      }
      if (totalMinute < 10) {
        totalMinute = "0" + totalMinute;
      }
    }
    // if (totalMinute >= 60) {
    //   totalHour++;
    //   totalMinute = totalMinute - 60;
    //   console.log("total hour", totalHour);
    //   console.log("total minute", totalMinute);
    //   if (totalHour >= 24) {
    //     totalHour = Math.floor(totalHour - 24);
    //   }
    //   if (totalHour < 10) {
    //     totalHour = "0" + totalHour;
    //   }
    //   if (totalMinute < 10) {
    //     totalMinute = "0" + totalMinute;
    //   }
    // }
    if (totalHour >= 24) {
      totalHour = Math.floor(totalHour - 24);
    }
    let time = Math.floor(totalHour) + ":" + totalMinute;
    return time;
  };

  useEffect(() => {
    let getTotalPomo = 0;
    let getAct = 0;
    tasks.map((task) => {
      task.estimatedPomo < task.currentPomo
        ? (getTotalPomo += task.currentPomo)
        : (getTotalPomo += task.estimatedPomo);
      getAct += task.currentPomo;
    });
    setAct(getAct);
    setTotalPomo(getTotalPomo);
    if (getTotalPomo > 0) {
      setFinishAt(
        calculateFinishAt(
          getTotalPomo * settingInfo.pomoMinute,
          getTotalPomo,
          act
        )
      );
    }
  }, [tasks, settingInfo]);
  const addTask = (task) => {
    console.log("task", task);
    if (!task.taskName) {
      return;
    }
    const newTask = [...tasks, task];

    if (newTask.length === 1) {
      settingInfo.setFocusTaskId({
        count: task.currentPomo,
        id: task.id,
      });
      settingInfo.setCurrentUserSession({ ...task });
    }
    encryptAndStoreData("current-local-sessions", newTask);

    setTasks(newTask);
  };
  return (
    <div>
      {/* <div className={classes.containerTasks}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>Tasks</Typography>
        <div
          className={classes.containerTasksButton}
          onClick={() => {
            setShowEdit((prev) => !prev);
          }}
        >
          <button className={classes.buttonTask}>
            <MoreVertIcon sx={{ width: 25 }} />
          </button>

          {showEdit && (
            <div className={classes.editButton} ref={editRef}>
              <div
                className={classes.editOptions}
                onClick={handleDeleteFinishedTasks}
              >
                <DeleteOutlineIcon
                  sx={{ opacity: 0.8, width: 20, marginRight: "8px" }}
                />
                Clear finished tasks
              </div>

              <div
                className={classes.editOptions}
                onClick={handleClickSaveTemplates}
              >
                <InsertDriveFileIcon
                  sx={{ opacity: 0.8, width: 20, marginRight: "8px" }}
                />
                Save as templates
              </div>
              <div
                className={classes.editOptions}
                onClick={() => {
                  handleAddTemplates();
                }}
              >
                <AddIcon sx={{ opacity: 0.8, width: 20, marginRight: "8px" }} />
                Add from templates
              </div>
              <div
                className={classes.editOptions}
                onClick={handleDeleteAllTasks}
              >
                <DeleteOutlineIcon
                  sx={{ opacity: 0.8, width: 20, marginRight: "8px" }}
                />
                Clear all tasks
              </div>
            </div>
          )}
        </div>
      </div> */}
      <TaskItem tasks={tasks} setTasks={setTasks} />
      {settingInfo.showInputTask ? (
        <TaskForm onSubmit={addTask} />
      ) : (
        <div className="group flex justify-center items-center mt-4 mb-4">
          <PlusIcon
            className="h-6 w-6 fill-sky-300 cursor-pointer group-hover:fill-sky-400"
            onClick={handleAddTaskButton}
          />
          <span
            className="text-lg font-medium cursor-pointer text-sky-300 group-hover:text-sky-400"
            onClick={handleAddTaskButton}
          >
            Add new task
          </span>
        </div>
      )}
      {tasks.length > 0 && (
        <div className={classes.containerEstimation}>
          <div className="inline-flex">
            <div className={classes.estimationItem}>
              Total pomos:
              <span className={classes.estimationValue}>{totalPomo}</span>
            </div>
            <div className={classes.estimationItem}>
              Done:
              <span className={classes.estimationValue}>{act}</span>
            </div>
            <div className={classes.estimationItem}>
              Finish at:
              <span className={classes.estimationValue}>{finishAt}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(TaskList, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
