import React, { createContext, useContext, useRef, useState } from "react";
import { AuthContext } from "./AuthContext";
import {
  encryptAndStoreData,
  retrieveAndDecryptData,
} from "../components/common/encryptedStorage";
const storage = retrieveAndDecryptData("default");
const defaultStorage = {
  pomoValue: storage ? storage?.pomoValue : 15,
  shortValue: storage ? storage?.shortValue : 5,
  longValue: storage ? storage?.longValue : 15,
  state: storage ? storage?.state : 0,
  isAutoStartPomo: storage ? storage?.isAutoStartPomo : false,
  isAutoStartBreak: storage ? storage?.isAutoStartBreak : false,
  longBreakInterval: storage ? storage?.longBreakInterval : 3,
  isAlarmSoundOn: storage ? storage?.isAlarmSoundOn : true,
  isTickingSoundOn: storage ? storage?.isTickingSoundOn : false,
  isPushNotificationOn: storage ? storage?.isPushNotificationOn : false,
  notifyLastMinutes: storage ? storage?.notifyLastMinutes : 5,
};
encryptAndStoreData("default", defaultStorage);
export const SettingContext = createContext();
export default function SettingProvider({ children }) {
  const { user } = useContext(AuthContext);

  // const storage = JSON.parse(localStorage.getItem("default"));
  const videoYoutubeDefault = {
    kind: "youtube#searchResult",
    etag: "hWlO_YeYrC93aXrxx4fw5X5d5Mc",
    id: "jfKfPfyJRdk",
    snippet: {
      publishedAt: "2022-07-12T12:12:29Z",
      channelId: "UCSJ4gkVC6NrvII8umztf0Ow",
      title: "lofi hip hop radio - beats to relax/study to",
      description:
        "Thank you for listening, I hope you will have a good time here | Get the latest vinyl (limited edition) ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/jfKfPfyJRdk/default_live.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/jfKfPfyJRdk/mqdefault_live.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/jfKfPfyJRdk/hqdefault_live.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Lofi Girl",
      liveBroadcastContent: "live",
      publishTime: "2022-07-12T12:12:29Z",
    },
  };
  const [pomoMinute, setPomoMinute] = useState(
    storage ? storage.pomoValue : 15
    // 0.1,
  );
  const [shortBreakMinute, setShortBreakMinute] = useState(
    storage ? storage.shortValue : 5,
    // 0.1
  );
  const [longBreakMinute, setLongBreakMinute] = useState(
    storage ? storage.longValue : 15,
  );
  const currentUserSessionLS = retrieveAndDecryptData("current-local-sessions");
  const [currentUserSession, setCurrentUserSession] = useState(
    currentUserSessionLS ?? [],
  );
  const [allCurrentUserSessions, setAllCurrentUserSessions] = useState();
  const [isInSession, setIsInSession] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [isAutoStartBreak, setIsAutoStartBreak] = useState(
    storage ? storage.isAutoStartBreak : false,
  );
  const [isAutoStartPomo, setIsAutoStartPomo] = useState(
    storage ? storage.isAutoStartPomo : false,
  );
  const [isAlarmSoundOn, setIsAlarmSoundOn] = useState(
    storage ? storage.isAlarmSoundOn : false,
  );
  const [isTickingSoundOn, setIsTickingSoundOn] = useState(
    storage ? storage.isTickingSoundOn : false,
  );
  const [isPushNotificationOn, setIsPushNotificationOn] = useState(
    storage ? storage.isPushNotificationOn : false,
  );
  const [notifyLastMinutes, setNotifyLastMinutes] = useState(
    storage ? storage.notifyLastMinutes : 5,
  );
  const [longBreakInterval, setLongBreakInterval] = useState(
    storage ? storage.longBreakInterval : 3,
  );
  const [numberPomoPlayed, setNumberPomoPlayed] = useState(0);
  const [pomoBackground, setPomoBackground] = useState(
    storage ? (storage.state === 0 ? true : false) : false,
  );
  const [shortBackground, setShortBackground] = useState(
    storage ? (storage.state === 1 ? true : false) : false,
  );
  const [longBackground, setLongBackground] = useState(
    storage ? (storage.state === 2 ? true : false) : false,
  );
  const [settingConfirm, setSettingConfirm] = useState(false);
  const [previousValuePomo, setPreviousValuePomo] = useState(pomoMinute);
  const [previousValueShort, setPreviousValueShort] =
    useState(shortBreakMinute);
  const [previousValueLong, setPreviousValueLong] = useState(longBreakMinute);
  const [showInputTask, setShowInputTask] = useState(false);
  const [focusTaskId, setFocusTaskId] = useState({
    id: null,
    count: 0,
  });
  // Refactor this with a modal component
  const [showPlayPomoModal, setShowPlayPomoModal] = useState(false);
  const [showPausePomoModal, setShowPausePomoModal] = useState(false);
  const [showSkipSessionModal, setShowSkipSessionModal] = useState(false);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [openMusicComponent, setOpenMusicComponent] = useState(false);
  const [userCategory, setUserCategory] = useState({});
  // youtube
  const [currentTimeOfYoutube, setCurrentTimeOfYoutube] = useState(0);
  const [isPlayYoutubeNow, setIsPlayYoutubeNow] = useState(false);
  const [playNow, setPlayNow] = useState(false);
  const [youtubeList, setYoutubeList] = useState([videoYoutubeDefault]);
  const [currentYoutube, setCurrentYoutube] = useState(videoYoutubeDefault);
  const [currentChannel, setCurrentChannel] = useState();
  const [isSearchingYoutube, setIsSearchingYoutube] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [openPopUpYoutube, setOpenPopUpYoutube] = useState(false);
  const [isPlayPopUpYoutubeNow, setIsPlayPopUpYoutubeNow] = useState(false);
  const [resetTimerFlag, setResetTimerFlag] = useState(false);

  const popUpYoutubeRef = useRef();
  const youtubeRef = useRef();
  const youtubeImgDefault =
    "https://yt3.googleusercontent.com/584JjRp5QMuKbyduM_2k5RlXFqHJtQ0qLIPZpwbUjMJmgzZngHcam5JMuZQxyzGMV5ljwJRl0Q=s176-c-k-c0x00ffffff-no-rj";
  //

  const template = user?.template;
  const googleImgDefault =
    "https://ssl.gstatic.com/images/branding/product/2x/avatar_circle_blue_64dp.png";

  const value = {
    resetTimerFlag,
    setResetTimerFlag,
    pomoMinute,
    setPomoMinute,
    shortBreakMinute,
    currentUserSession,
    setCurrentUserSession,
    allCurrentUserSessions,
    setAllCurrentUserSessions,
    isInSession,
    setIsInSession,
    setShortBreakMinute,
    longBreakMinute,
    setLongBreakMinute,
    showSetting,
    setShowSetting,
    showReport,
    setShowReport,
    isAutoStartBreak,
    setIsAutoStartBreak,
    isAutoStartPomo,
    setIsAutoStartPomo,
    isAlarmSoundOn,
    setIsAlarmSoundOn,
    isTickingSoundOn,
    setIsTickingSoundOn,
    notifyLastMinutes,
    setNotifyLastMinutes,
    isPushNotificationOn,
    setIsPushNotificationOn,
    numberPomoPlayed,
    setNumberPomoPlayed,
    longBreakInterval,
    setLongBreakInterval,
    shortBackground,
    pomoBackground,
    longBackground,
    setShortBackground,
    setPomoBackground,
    setLongBackground,
    settingConfirm,
    setSettingConfirm,
    previousValuePomo,
    setPreviousValuePomo,
    previousValueShort,
    previousValueLong,
    setPreviousValueShort,
    setPreviousValueLong,
    showInputTask,
    setShowInputTask,
    focusTaskId,
    setFocusTaskId,
    template,
    showAddTemplate,
    setShowAddTemplate,
    showSaveTemplate,
    setShowSaveTemplate,
    openTask,
    setOpenTask,
    userCategory,
    setUserCategory,
    openMusicComponent,
    setOpenMusicComponent,
    currentYoutube,
    setCurrentYoutube,
    youtubeList,
    setYoutubeList,
    currentChannel,
    setCurrentChannel,
    isSearchingYoutube,
    setIsSearchingYoutube,
    playNow,
    setPlayNow,
    youtubeImgDefault,
    googleImgDefault,
    isLoadingVideo,
    setIsLoadingVideo,
    videoYoutubeDefault,
    showPlayPomoModal,
    showPausePomoModal,
    setShowPausePomoModal,
    setShowPlayPomoModal,
    showSkipSessionModal,
    setShowSkipSessionModal,
    openPopUpYoutube,
    setOpenPopUpYoutube,
    isPlayPopUpYoutubeNow,
    setIsPlayPopUpYoutubeNow,
    currentTimeOfYoutube,
    setCurrentTimeOfYoutube,
    popUpYoutubeRef,
    youtubeRef,
    isPlayYoutubeNow,
    setIsPlayYoutubeNow,
  };
  return (
    <SettingContext.Provider value={value}>{children}</SettingContext.Provider>
  );
}
