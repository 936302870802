import React, { memo, useMemo } from "react";
import DailyTask from "./DailyTask";
import moment from "moment";

const DailyReport = ({ data }) => {
  const sessionsByMonth = data?.data;
  const groupSessionsByDate = () => {
    const formatDate = "YYYY-MM-DD"; // format to compare dates
    const groupedSessions = sessionsByMonth
      ? sessionsByMonth.sessions.reduce((groups, session) => {
          const date = moment(session.date).format("YYYY-MM-DD"); // format date for comparing

          if (groups[date]) {
            groups[date].push(session);
          } else {
            groups[date] = [session];
          }
          return groups;
        }, {})
      : {};
    return Object.values(groupedSessions);
  };
  const memoHandleGroupSessionsByDate = useMemo(
    () => groupSessionsByDate(),
    [data]
  );
  return (
    <div className="overflow-auto max-h-screen ">
      {data.data ? (
        <div className="p-4">
          {memoHandleGroupSessionsByDate.map((dailyData, index) => (
            <div
              className="min-w-full w-full max-w-full  cursor-default"
              key={index}
            >
              <DailyTask key={index} data={dailyData} />
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4">
          <span className="text-sm  cursor-default text-zinc-600 ">
            You haven't done any sessions in this month yet.
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(DailyReport, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
