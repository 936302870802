import { memo, useState } from "react";
import Chart from "react-apexcharts";
import { months } from "../../common/global";

const DatePicker = ({ data }) => {
  const [chart, setChart] = useState({
    series: [
      {
        name: "series1",
        data: data.chartData,
      },
    ],
  });
  const options = {
    chart: {
      type: "area",
      height: 80,
      minWidth: 250,
      maxWidth: 320,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#38bdf8", "#7dd3fc", "#bae6fd", "white"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    tooltip: { enabled: false },
  };
  return (
    <div className="  flex space-y-2  w-full outline-none   hover:bg-gray-400/20 p-4  cursor-pointer ">
      {/* selected bg-gray-300*/}
      <div className={"text-left w-full"}>
        <span className="text-lg font-semibold">
          {months[data.date.month]}{" "}
          <span className=" font-medium text-gray-700">{data.date.year}</span>
        </span>
        <Chart
          options={options}
          series={chart.series}
          type="area"
          height={90}
          minWidth={250}
          maxWidth={320}
        />
        <div className={"text-sm font-semibold "}>
          <span>
            {data.data ? data.data.totalPomos : "0"}
            <span className={"font-medium text-gray-600"}> pomos </span>
          </span>
          <span className={"m-1"}>
            {/*&#183;*/}
            &bull;
          </span>

          <span>
            {data.data ? parseInt(data.data.totalPomoTime / 60) : "0"}h
            {data.data ? Math.round(data.data.totalPomoTime) % 60 : "0"}m
            <span className={"font-medium text-gray-600"}> on pomos </span>
          </span>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className=" w-5 h-5 self-center text-gray-500"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  );
};

export default memo(DatePicker, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});
