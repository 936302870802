import { createContext } from "react";
import { io } from "socket.io-client";
const backendUrl = process.env.REACT_APP_BE_URL_DEV;
const websocketUrl = backendUrl.replace(/^http?:\/\//, 'ws://');
// const websocketUrl = backendUrl.replace(/^https?:\/\//, 'wss://');
export const socket = io(websocketUrl, {
  withCredentials: true,
  transports: ["websocket"],
});
export const WebsocketContext = createContext();
export default function WebsocketProvider({ children }) {
  return (
    <WebsocketContext.Provider value={socket}>
      {children}
    </WebsocketContext.Provider>
  );
}
