export const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
export const days = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};

export const workTime = {
  0: "Night",
  1: "Night",
  2: "Night",
  3: "Night",
  4: "Night",
  5: "Night",
  6: "Morning",
  7: "Morning",
  8: "Morning",
  9: "Morning",
  10: "Morning",
  11: "Morning",
  12: "Noon",
  13: "Noon",
  14: "Noon",
  15: "Noon",
  16: "Noon",
  17: "Noon",
  18: "Evening",
  19: "Evening",
  20: "Evening",
  21: "Evening",
  22: "Evening",
  23: "Evening",
};

export const colors = [
  "#334155",
  "#1E293B",
  "#4B5563",
  "#A1A1AA",
  "#A3A3A3",
  "#A8A29E",
  "#78716C",
  "#FEE2E2",
  "#F87171",
  "#EF4444",
  "#DC2626",
  "#B91C1C",
  "#FFEDD5",
  "#FB923C",
  "#F97316",
  "#EA580C",
  "#C2410C",
  "#FCD34D",
  "#FBBF24",
  "#F59E0B",
  "#D97706",
  "#B45309",
  "#FDE047",
  "#FACC15",
  "#EAB308",
  "#CA8A04",
  "#A16207",
  "#BEF264",
  "#A3E635",
  "#84CC16",
  "#65A30D",
  "#4D7C0F",
  "#86EFAC",
  "#4ADE80",
  "#22C55E",
  "#16A34A",
  "#15803D",
  "#6EE7B7",
  "#34D399",
  "#10B981",
  "#059669",
  "#047857",
  "#5EEAD4",
  "#2DD4BF",
  "#14B8A6",
  "#0D9488",
  "#0F766E",
  "#67E8F9",
  "#22D3EE",
  "#06B6D4",
  "#0891B2",
  "#0E7490",
  "#7DD3FC",
  "#38BDF8",
  "#0EA5E9",
  "#0284C7",
  "#0369A1",
  "#93C5FD",
  "#60A5FA",
  "#3B82F6",
  "#2563EB",
  "#1D4ED8",
  "#A5B4FC",
  "#818CF8",
  "#6366F1",
  "#4F46E5",
  "#4338CA",
  "#C4B5FD",
  "#A78BFA",
  "#8B5CF6",
  "#7C3AED",
  "#6D28D9",
  "#D8B4FE",
  "#C084FC",
  "#A855F7",
  "#9333EA",
  "#7E22CE",
  "#F0ABFC",
  "#E879F9",
  "#D946EF",
  "#C026D3",
  "#A21CAF",
  "#F9A8D4",
  "#F472B6",
  "#EC4899",
  "#DB2777",
  "#BE185D",
  "#FDA4AF",
  "#FB7185",
  "#F43F5E",
  "#E11D48",
  "#BE123C",
];
const FebDaysLeapYear = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29,
];
const FebDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28,
];
const ThirtyDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];
const ThirtyOneDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const handleGenerateXAsisDays = (month, year) => {
  const monthLength = new Date(year, month, 0).getDate();
  if (monthLength === 28) return FebDays;
  if (monthLength === 29) return FebDaysLeapYear;
  if (monthLength === 30) return ThirtyDays;
  if (monthLength === 31) return ThirtyOneDays;
};

export const getMinDifference = (startDate, endDate) => {
  let startDateMS = startDate.getTime();
  let endDateMS = endDate.getTime();

  let msDifference = Math.abs(startDateMS - endDateMS);

  let minDiff = Math.floor(msDifference / (1000 * 60));
  return minDiff <= 0 ? 1 : minDiff;
};

export const defaultDatePicker = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  let defaultData = [];
  for (let i = 0; i < 13; i++) {
    let month = currentMonth - i;
    let year = currentYear;
    if (month <= 0) {
      month += 12;
      year -= 1;
    }
    const date = { month, year };
    const data = null;
    const chartData = Array(30).fill(0);

    defaultData.push({ date, data, chartData });
  }
  return defaultData;
};

export const FEATURES = {
  standard: {
    monthly: {
      value: "Free",
    },
    description: "Perfect fits for personal use",
    ctaText: "Start here",
    features: [
      "Pomodoro with a To-do list",
      "Analytic data for 12 months",
      "Unlimited categories",
      "YouTube Integration",
      "Virtual learning room to work with friends",
      "Free 30k AI Chat tokens",
    ],
  },
  premium: {
    monthly: {
      value: 5,
      currency: "$",
    },
    description: "Enjoy higher limits and more advanced features",
    ctaText: "Go Premium ✨",
    features: [
      "Analytic data for lifetime",
      "Up to 100k AI chat tokens",
      "Chat with PDF AI up to 50 files monthly",
      "Virtual learning room to work with friends",
    ],
  },
};
