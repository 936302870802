import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

const PricingBox = ({
  ctaText,
  features,
  description,
  name,
  price,
  currentPlan,
  user,
}) => {
  const handleBuySubscription = () => {
    if (user && user.subscription === "standard") {
      const url =
        process.env.REACT_APP_LEMON_SQUEEZY_CHECKOUT_URL +
        `/?checkout[custom][email]=${user.email}
        &checkout[custom][user_id]=${user._id}`;
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else if (!user) {
      const url = process.env.REACT_APP_LEMON_SQUEEZY_CHECKOUT_URL;
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };
  return (
    <div
      className={`px-4 py-2 ${
        currentPlan === name && "bg-zinc-100"
      } bg-white flex flex-col border-solid border-[2px] border-sky-400 w-full rounded-xl h-fit`}
    >
      <span
        className={` 
                rounded-lg ml-2 mt-2 w-fit  font-semibold `}
      >
        {/* ${
          name === "Standard" ? "bg-sky-400" : "bg-amber-300"
        }   */}

        {name.toUpperCase()}
      </span>
      <div className="">
        <span className="text-4xl font-bold">
          {price.value}
          {price.currency}
        </span>
        {price.currency && <span className="font-semibold">/month</span>}
      </div>

      <span className="mt-1 mb-4 text-base font-normal text-zinc-600">
        {description}
      </span>

      {name === "premium" && (
        <div className="flex w-full flex-row font-medium ml-2 mb-2 self-start space-x-[2px]">
          {/* <CheckIcon className="h-4 w-4 fill-green-400" /> */}

          <span className="font-semibold">All standard features plus:</span>
        </div>
      )}
      {features.map((feature, index) => (
        <span key={`feature-${index}`}>
          <span
            className="flex text-base font-medium
           self-start items-center space-x-[2px] ml-2 mb-2"
          >
            <div>
              <CheckIcon className="h-4 w-4 fill-green-400" />
            </div>
            <span className="text-left">{feature}</span>
          </span>
        </span>
      ))}
      <button
        className={`${
          name !== currentPlan
            ? name === "standard"
              ? "bg-sky-400"
              : "bg-amber-300"
            : name === "standard"
            ? "bg-sky-600 cursor-default"
            : "bg-amber-400 cursor-default"
        }  font-semibold mx-auto rounded-xl outline-none focus:ring-2 focus:ring-sky-400 text-white w-fit mt-auto py-2 px-4 my-2`}
        onClick={handleBuySubscription}
      >
        {currentPlan === name
          ? currentPlan === "standard"
            ? "Current plan"
            : "Current plan ✨"
          : ctaText}
      </button>
    </div>
  );
};
export default PricingBox;
