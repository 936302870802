import React, { memo } from "react";
import VideoSearch from "./VideoSearch";

const SearchList = ({ responseList }) => {
  return (
    <div className="space-y-3 bg-gray-50 rounded-xl ">
      {responseList?.items?.map((response) => (
        <div className="rounded-xl bg-gray-100 " key={response.etag}>
          <VideoSearch detail={response} />
        </div>
      ))}
    </div>
  );
};

export default memo(SearchList, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
