import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SettingContext } from "../../context/SettingContext";
import { useQuery } from "@tanstack/react-query";
import { getUserCategory } from "../../api/service";

export const useUserCategory = () => {
  const { isLogin } = useContext(AuthContext);
  const settingInfo = useContext(SettingContext);
  const { data: userCategories } = useQuery(
    ["user-categories"],
    () => getUserCategory(),
    {
      onSuccess: (categories) => {
        settingInfo.setUserCategory(categories);
      },
      onError: () => {
        settingInfo.setUserCategory([]);
      },
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(isLogin),
    }
  );
  return userCategories?.length ? { userCategories } : [];
};
