import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import logo from "../../assets/favicon.ico";
import { SettingContext } from "../../context/SettingContext";
import { AuthContext } from "../../context/AuthContext";
import {
  ForwardIcon,
  MusicalNoteIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/solid";
import ModalConfirmation from "../common/ModalConfirmation";
import {
  encryptAndStoreData,
  retrieveAndDecryptData,
} from "../common/encryptedStorage";
import { createUserBreakSession, createUserSession } from "../../api/service";
import TimerPlayerComponent from "./TimerPlayer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getMinDifference } from "../common/global";

const useStyles = makeStyles({
  pomodoroArea: {
    maxWidth: 620,
    margin: "auto",
    padding: "0px 12px",
  },
  pomodoroContent: {
    backgroundColor: "rgba(255,255,255,0.1)",
    padding: " 20px 0px 30px",
    marginBottom: 20,
    borderRadius: 6,
    width: "100%",
  },
  list: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timer: {
    flexBasis: "50%",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "center",
  },
  start: {
    display: "flex",
    zIndex: 999999,
  },
  startButton: {
    cursor: "pointer",
    margin: "20px 0px 0px",
    padding: "0px 12px",
    borderRadius: 8,
    boxShadow: "rgb(235 235 235) 0px 6px 0px",
    fontSize: 22,
    height: 50,
    color: "white",
    fontWeight: "bold",
    width: 200,
    backgroundColor: "white",
    border: "none",
    transition: "0.3s ease-in-out 0s",
  },
  stopButton: {
    cursor: "pointer",
    margin: "20px 0px 0px",
    padding: "0px 12px",
    borderRadius: 4,
    fontSize: 22,
    height: 55,
    color: "white",
    fontWeight: "bold",
    width: 200,
    backgroundColor: "white",
    border: "none",
    transform: "translateY(6px)",
    boxShadow: "none",
    transition: "0.2s ease-in-out 0s",
  },
  progressArea: {
    marginBottom: 40,
    backgroundColor: "rgba(0,0,0,0.1)",
    height: "1px",
  },
  progress: {
    backgroundColor: "white",
  },
  nextButton: {
    marginLeft: 20,
    cursor: "pointer",
    height: 54,
    bottom: -6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    zIndex: -9999,
    width: "calc( (100% - 200px)/2 + 40%)",
  },
  pomodoroAlign: {
    textAlign: "center",
  },
  pomodoroContainerContent: {
    maxWidth: 480,
    margin: "auto",
  },
});

let currentUserSessions =
  retrieveAndDecryptData("current-local-sessions") ?? [];
const Pomodoro = ({ tasks, openTaskForm, handlerMusicComponent }) => {
  const queryClient = useQueryClient();
  const createNewSessionMutation = useMutation(
    (newSession) => createUserSession(newSession),
    {
      onSuccess: () => {
        let currentMonthYearObject = {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
        };
        queryClient.invalidateQueries({
          queryKey: ["daily-statistic-data", currentMonthYearObject],
          exact: true,
        });
        queryClient.invalidateQueries({
          queryKey: ["user-sessions-by-month"],
          exact: true,
        });
        toast.success("Created session successfully!");
      },
      onError: (e) => {
        toast.error(`Failed to create new session! ${e.data.message}`);
      },
    }
  );
  const createBreakSession = useMutation(
    (newBreakSession) => {
      return createUserBreakSession(newBreakSession);
    },
    {
      onSuccess: () => {
        toast.success("Created break session successfully!");
      },
      onError: (e) => {
        toast.error(`Failed to create break session! ${e.data.message}`);
      },
    }
  );
  // user
  const { user } = useContext(AuthContext);
  // REFACTOR
  // const storage = JSON.parse(localStorage.getItem("default"));
  const storage = retrieveAndDecryptData("default");
  /////
  // setting
  const settingInfo = useContext(SettingContext);
  const classes = useStyles();
  const [second, setSecond] = useState(0);
  const [option, setOption] = useState(storage ? +storage.state : 0);
  const [play, setPlay] = useState(false);
  const [render, setRender] = useState(false);
  const [pomoChange, setPomoChange] = useState(false);
  const [shortChange, setShortChange] = useState(false);
  const [longChange, setLongChange] = useState(false);
  const [playAlarm, setPlayAlarm] = useState(false);
  // ref
  const optionRef = useRef(option);
  const playRef = useRef(play);
  const secondRef = useRef(second);
  const modalRef = useRef(null);
  const alarmRef = useRef();
  //  task ref
  const taskRef = useRef({}).current;
  taskRef.id = settingInfo.focusTaskId.id;
  const currentUserSession = settingInfo.currentUserSession;
  const getCurrentTask = tasks.filter((e) => e.id === taskRef.id);
  const breakSession = useMemo(() => {
    return (
      user && {
        sessionTitle: "",
        sessionDescription: "",
        sessionTime:
          option === 1
            ? settingInfo.shortBreakMinute
            : settingInfo.longBreakMinute,
        sessionStartAt: new Date().toISOString(),
        disruptedNumber: 0,
        disruptedList: [],
        date: new Date().toISOString(),
      }
    );
  }, [
    optionRef.current,
    user,
    settingInfo.shortBreakMinute,
    settingInfo.longBreakMinute,
  ]);
  let currentTask = optionRef.current === 0 ? getCurrentTask[0] : breakSession;
  let totalSeconds;
  if (option === 0) {
    totalSeconds = settingInfo.pomoMinute * 60;
  } else if (option === 1) {
    totalSeconds = settingInfo.shortBreakMinute * 60;
  } else {
    totalSeconds = settingInfo.longBreakMinute * 60;
  }

  let percent = (1 - second / totalSeconds) * 100;
  const minute = Math.floor(second / 60);
  let seconds = second % 60;

  const count = () => {
    secondRef.current--;
    setSecond(Math.round(secondRef.current));
    let minuteTitle = Math.floor(secondRef.current / 60);
    document.title = `${
      optionRef.current === 0
        ? "stayinpomo: "
        : optionRef.current === 1
        ? "Short break: "
        : "Long break: "
    }  ${minuteTitle < 10 ? `0${minuteTitle}` : minuteTitle}:${
      secondRef.current % 60 < 10
        ? `0${secondRef.current % 60}`
        : secondRef.current % 60
    }`;

    if (
      secondRef.current === settingInfo.notifyLastMinutes * 60 &&
      settingInfo.isPushNotificationOn
    ) {
      // push notification
      new Notification("stayinpomo", {
        title: "stayinpomo",
        body: `You have ${settingInfo.notifyLastMinutes} minutes left for the session!`,
        icon: logo,
      });
    }
  };
  const playAudio = () => {
    console.log("play audio");
    new Audio(
      "https://cdn.pixabay.com/audio/2022/03/09/audio_4b7bb9d4c1.mp3"
      // ticking sound
      // "https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_household_clock_old_tick_tock_003_69496.mp3"
      //https://cdn.pixabay.com/audio/2022/03/09/audio_4b7bb9d4c1.mp3
    ).play();
    // alarm sound
    // https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-three/emergency_bell_alarm_small_ring.mp3
    //https://cdn.pixabay.com/audio/2022/03/19/audio_6492e3e218.mp3
  };

  // default option value
  // Calculate the pomo value and save to local storage as well
  // Calculate include the remain time if user change the setting time in runtime
  const defaultValue = (
    e,
    previousSecondRef,
    previousPomoMinute,
    previousShortMinute,
    previousLongMinute
  ) => {
    setPlay(false);
    playRef.current = false;
    if (e === 0) {
      storage.state = 0;
      let remainTime = 0;
      if (previousSecondRef > 0) {
        // if user want to change the setting time in runtime, calculate again, to take away the time user have played
        remainTime = previousPomoMinute * 60 - previousSecondRef;
      }
      secondRef.current = settingInfo.pomoMinute * 60 - remainTime;
      settingInfo.setPomoBackground(true);
      settingInfo.setShortBackground(false);
      settingInfo.setLongBackground(false);
    }
    if (e === 1) {
      storage.state = 1;
      settingInfo.setShortBackground(true);
      settingInfo.setPomoBackground(false);
      settingInfo.setLongBackground(false);
      let remainTime = 0;
      if (previousSecondRef > 0) {
        // if user want to change the setting time in runtime, calculate again, to take away the time user have played
        remainTime = previousShortMinute * 60 - previousSecondRef;
      }
      secondRef.current = settingInfo.shortBreakMinute * 60 - remainTime;
    }
    if (e === 2) {
      storage.state = 2;
      settingInfo.setLongBackground(true);
      settingInfo.setShortBackground(false);
      settingInfo.setPomoBackground(false);
      let remainTime = 0;
      if (previousSecondRef > 0) {
        // if user want to change the setting time in runtime, calculate again, to take away the time user have played
        remainTime = previousLongMinute * 60 - previousSecondRef;
      }
      secondRef.current = settingInfo.longBreakMinute * 60 - remainTime;
    }
    setSecond(secondRef.current);
    encryptAndStoreData("default", storage);
  };

  // if the pomo value is changed, the timer will be re-render
  // and we will check for each type: pomo, break, long break
  const check = () => {
    if (optionRef.current === 0) {
      if (settingInfo.previousValuePomo !== settingInfo.pomoMinute) {
        setPomoChange((prev) => !prev);
      }
    } else if (optionRef.current === 1) {
      if (settingInfo.previousValueShort !== settingInfo.shortBreakMinute) {
        setShortChange((prev) => !prev);
      }
    } else {
      if (settingInfo.previousValueLong !== settingInfo.longBreakMinute) {
        setLongChange((prev) => !prev);
      }
    }
  };

  // when the value of the setting change, the timer will should be re-render
  // and we will check for each type: pomo, break, long break
  useEffect(() => {
    if (optionRef.current === 0) {
      if (settingInfo.previousValuePomo !== settingInfo.pomoMinute) {
        setRender((prev) => !prev);
      }
    }
  }, [pomoChange]);
  useEffect(() => {
    if (optionRef.current === 1) {
      if (settingInfo.previousValueShort !== settingInfo.shortBreakMinute) {
        setRender((prev) => !prev);
      }
    }
  }, [shortChange]);
  useEffect(() => {
    if (optionRef.current === 2) {
      if (settingInfo.previousValueLong !== settingInfo.longBreakMinute) {
        setRender((prev) => !prev);
      }
    }
  }, [longChange]);

  //  any setting/properties in that option change? YES => set render to true
  useEffect(() => {
    check();
  }, [settingInfo.settingConfirm]);

  // when the user change the setting, the previous value = the current value, so we can store the previous value
  useEffect(() => {
    settingInfo.setPreviousValuePomo(settingInfo.pomoMinute);
    settingInfo.setPreviousValueShort(settingInfo.shortBreakMinute);
    settingInfo.setPreviousValueLong(settingInfo.longBreakMinute);
    defaultValue(
      optionRef.current,
      secondRef.current,
      settingInfo.previousValuePomo,
      settingInfo.previousValueShort,
      settingInfo.previousValueLong
    );
  }, [render]);
  const changeOption = () => {
    let numberPomoPlayed = settingInfo.numberPomoPlayed;
    if (optionRef.current === 0) {
      numberPomoPlayed = settingInfo.numberPomoPlayed;
      numberPomoPlayed += 1;
      settingInfo.setNumberPomoPlayed(numberPomoPlayed);
    }
    // if numberPomoPlayed is not 0 and can be divided by longBreakInterval, then change to long break
    // because it's time for long break
    const nextOption =
      numberPomoPlayed !== 0 &&
      numberPomoPlayed % settingInfo.longBreakInterval === 0
        ? 2
        : optionRef.current === 0
        ? 1
        : 0;
    const nextSecond =
      nextOption === 0
        ? settingInfo.pomoMinute * 60
        : nextOption === 1
        ? settingInfo.shortBreakMinute * 60
        : settingInfo.longBreakMinute * 60;
    setOption(nextOption);
    optionRef.current = nextOption;
    setSecond(nextSecond);
    secondRef.current = nextSecond;
    if (settingInfo.isAutoStartBreak || settingInfo.isAutoStartPomo) {
      defaultValue(optionRef.current, 0, 0, 0, 0);
      setPlay(true);
      playRef.current = true;
    } else {
      defaultValue(optionRef.current, 0, 0, 0, 0);
    }
  };

  // Main check for the timer
  useEffect(() => {
    if (settingInfo.resetTimerFlag) {
      defaultValue(optionRef.current, 0, 0, 0, 0);
      settingInfo.setResetTimerFlag(false);
    }
    const interval = setInterval(async () => {
      if (playRef.current === false) {
        return;
      }
      if (secondRef.current === 0) {
        // DONE TASK
        handleProcessDoneCurrentSession();

        return setInterval(1000);
      }

      count();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [
    settingInfo.numberPomoPlayed,
    settingInfo.autoBreak,
    settingInfo.pomoMinute,
    settingInfo.shortBreakMinute,
    settingInfo.longBreakMinute,
    currentTask,
    tasks,
  ]);

  const remindStartPomoOption = {
    title: "Start a new session without any task?",
    content:
      "You can start a enw session without any task, but this session will not be saved in the analytic report.",
    handleConfirm: () => {
      setPlay((play) => !play);
      playRef.current = !playRef.current;
      modalRef.current.close();
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Start",
    cancel: "Cancel",
  };

  const handleTurnOffAlarm = () => {
    // turn off alarm if it's playing
    // since this is annoying users if they start a new session while the alarm is playing
    if (playAlarm && alarmRef.current) {
      alarmRef.current.pause();
      setPlayAlarm(false);
    }
  };
  const onClickStartPomo = () => {
    handleTurnOffAlarm();
    if (user) {
      if (optionRef.current === 0 && (!currentTask || !tasks.length)) {
        // playAudio();
        modalRef.current.open(remindStartPomoOption);
        return;
      }
      // playAudio();
      modalRef.current.open(playPomoOption);
    } else {
      // handleStartPomoSession();
      setPlay((play) => !play);
      playRef.current = !playRef.current;
    }
  };
  const onClickPausePomo = () => {
    if (user) {
      if (optionRef.current === 0 && (!user || !currentTask || !tasks.length)) {
        setPlay((play) => !play);
        playRef.current = !playRef.current;
        return;
      }
      modalRef.current.open(pausePomoOption);
    } else {
      setPlay((play) => !play);
      playRef.current = !playRef.current;
    }
  };

  const skipPomoOption = {
    title: "Skip the current session?",
    content:
      "Are you sure you want to skip the session? The session will not be saved in the analytic report.",
    handleConfirm: () => {
      modalRef.current.close();
      setPlay(false);
      playRef.current = false;
      if (option === 0) {
        optionRef.current = option + 1;
        setOption((option) => option + 1);
        defaultValue(option + 1, 0, 0, 0, 0);
      }
      if (option === 1) {
        optionRef.current = option + 1;
        setOption((option) => option + 1);
        defaultValue(option + 1, 0, 0, 0, 0);
      }
      if (option === 2) {
        optionRef.current = 0;
        setOption(0);
        defaultValue(0, 0, 0, 0, 0);
      }
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Confirm",
    cancel: "Cancel",
  };
  const handleNext = () => {
    handleTurnOffAlarm();
    if (option === 0) {
      if (second !== totalSeconds) {
        modalRef.current.open(skipPomoOption);
      } else {
        optionRef.current = option + 1;
        setOption((option) => option + 1);
        defaultValue(option + 1, 0, 0, 0, 0);
      }
    }
    if (option === 1) {
      optionRef.current = option + 1;
      setOption((option) => option + 1);
      defaultValue(option + 1, 0, 0, 0, 0);
    }
    if (option === 2) {
      optionRef.current = 0;
      setOption(0);
      defaultValue(0, 0, 0, 0, 0);
    }
  };
  const handleStartPomoSession = () => {
    const currentDate = new Date();
    const currentUTC = currentDate.toISOString();
    // only set the start time if the task has not started yet
    if (!currentTask?.taskStartAt) {
      currentTask.taskStartAt = currentUTC;
    }
    // else if the task has been disrupted, set the restart time in the disrupted list
    if (currentTask.disruptedList?.length > 0) {
      currentTask.disruptedList[
        currentTask.disruptedList.length - 1
      ].restartAt = currentUTC;
    }
    settingInfo.setCurrentUserSession({
      user: user ? user : null,
      ...currentTask,
    });
  };
  const handlePausePomoSession = () => {
    const currentDate = new Date();
    const currentUTC = currentDate.toISOString();
    let disrupted = {
      disruptedAt: currentUTC,
    };
    let disruptedList = [...currentTask.disruptedList, disrupted];
    currentTask.disruptedList = disruptedList;
    currentTask.disruptedNumber = currentTask.disruptedNumber + 1;
    settingInfo.setCurrentUserSession({
      user: user ? user : null,
      ...currentTask,
    });
  };
  const playPomoOption = {
    title: "Start a new pomodoro session?",
    content:
      " Please make sure to stay in pomodoro mode for the entire duration of the pomo. Note that if you pause the timer, your" +
      " session will be marked as disrupted.",
    handleConfirm: () => {
      handleStartPomoSession();
      modalRef.current.close();
      setPlay((play) => !play);
      playRef.current = !playRef.current;
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Start",
    cancel: "Cancel",
  };
  const pausePomoOption = {
    title: "Pause this pomodoro session?",
    content:
      " Please make sure to stay in pomodoro mode for the entire duration of the pomo. Note that if you pause the timer, your" +
      "session will be marked as disrupted.",
    handleConfirm: () => {
      handlePausePomoSession();
      modalRef.current.close();
      setPlay((play) => !play);
      playRef.current = !playRef.current;
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Pause anyway",
    cancel: "Cancel",
  };
  const skipBreakOption = {
    title: "Skip break?",
    content: "Are you sure to skip break?",
    handleConfirm: () => {
      modalRef.current.close();
      optionRef.current = 0;
      setOption(0);
      defaultValue(0, 0, 0, 0, 0);
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Confirm",
    cancel: "Cancel",
  };

  const handleProcessDoneCurrentSession = (time) => {
    if (optionRef.current === 0) {
      if (taskRef !== null) {
        if (currentTask) {
          currentTask.currentPomo += 1;
          currentTask.done =
            currentTask.currentPomo >= currentTask.estimatedPomo;
          if (currentTask.done) {
            currentTask.taskDoneAt = new Date().toISOString();
          }
          console.log("current task", currentTask);
          let userSession = {
            sessionTitle: currentTask.taskName,
            // we allow user to done session early and save that time
            // if user done the session early, we will save the time they have done

            // if user done the session on time, we will save the setting time
            // we must save the setting time, since:
            // - user may have disrupted the session, if we get the time they have done based on start and end time
            // - it will be incorrect
            sessionTime: time ? time : settingInfo.pomoMinute,
            sessionDescription: currentTask.taskDescription,
            sessionStartAt: currentTask.taskStartAt,
            sessionDoneAt: new Date().toISOString(),
            disruptedNumber: currentTask.disruptedNumber,
            disruptedList: currentTask.disruptedList,
            date: new Date().toISOString(),
            category: currentTask.category?.id,
            categoryDetails: {
              name: currentTask.category?.label,
              color: currentTask.category?.color,
            },
          };
          if (user) {
            createNewSessionMutation.mutate(userSession);
          }
          // reset current task props that need for the BE only not for client
          // save to LS
          currentTask.taskDoneAt = null;
          currentTask.taskStartAt = null;
          currentTask.disruptedList = [];
          currentTask.disruptedNumber = 0;
          encryptAndStoreData("current-local-sessions", tasks);
          settingInfo.setFocusTaskId((prev) => ({
            count: prev.count + 1,
            id: prev.id,
          }));
        }
      }
    }
    // Create break session
    else if (optionRef.current === 1 || optionRef.current === 2) {
      if (user) {
        let userBreakSession = {
          sessionTitle: currentTask.sessionTitle,
          sessionTime: currentTask.sessionTime,
          sessionDescription: currentTask.sessionDescription,
          sessionStartAt: currentTask.taskStartAt,
          sessionDoneAt: new Date().toISOString(),
          disruptedNumber: currentTask.disruptedNumber,
          disruptedList: currentTask.disruptedList,
          date: new Date().toISOString(),
        };
        createBreakSession.mutate(userBreakSession);
      }
    }
    if (
      Notification.permission === "granted"
      // &&
      // settingInfo.isPushNotificationOn
    ) {
      let body =
        optionRef.current === 0
          ? currentTask && currentTask.currentPomo
            ? `You have done your ${currentTask.currentPomo}/${currentTask.estimatedPomo} pomodoro session!`
            : "You have done your pomodoro session!"
          : "You have done your break session!";
      new Notification("stayinpomo", {
        title: "stayinpomo",
        body: body,
        icon: logo,
      });
    }
    if (settingInfo.isAlarmSoundOn && alarmRef.current) {
      setPlayAlarm(true);
      alarmRef.current.currentTime = 0;
      alarmRef.current.volume = 0.4;
      alarmRef.current.play();
      // play this alarm for 5s
      setTimeout(() => {
        alarmRef.current.pause();
        setPlayAlarm(false);
      }, 5000);
    }
    document.title = "Done session!";
    return changeOption();
  };
  const doneEarlyOption = {
    title: "Done early?",
    content:
      "Are you sure you want to finish early? The remaining time will not be counted in the report.",
    handleConfirm: () => {
      let time = currentTask
        ? getMinDifference(new Date(currentTask.taskStartAt), new Date())
        : null;
      handleProcessDoneCurrentSession(time);
      modalRef.current.close();
    },
    handleCancel: () => {
      modalRef.current.close();
    },
    confirm: "Confirm",
    cancel: "Cancel",
  };
  const handleSkipBreak = () => {
    handleTurnOffAlarm();
    modalRef.current.open(skipBreakOption);
  };
  const handleDoneEarly = () => {
    handleTurnOffAlarm();
    modalRef.current.open(doneEarlyOption);
  };
  return (
    <>
      {/* <Notification /> */}
      <div className="mt-4 mb-4 justify-center items-center">
        <div className="inline-flex">
          {getCurrentTask.length > 0 ? (
            <div
              className="grid cursor-pointer "
              onClick={() => {
                openTaskForm.current.open();
              }}
            >
              {optionRef.current === 0 ? (
                <>
                  <span className="w-full text-base text-gray-300 font-medium">
                    Focusing on
                  </span>
                  <span className="w-full font-semibold text-4xl">
                    {currentTask.taskName}
                  </span>
                </>
              ) : (
                <>
                  <span className="w-full text-base text-gray-300 font-medium">
                    Time to break
                  </span>
                  <span className="w-full font-semibold text-4xl">
                    Break 🧠
                  </span>
                </>
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                openTaskForm.current.open();
              }}
            >
              <span className="mb-1 text-4xl group font-semibold cursor-pointer">
                What's&nbsp;
                <span className="inline-flex h-20 pt-2 overflow-x-hidden animate-type  whitespace-nowrap text-brand-accent will-change-transform">
                  your&nbsp;
                  <span className="focus text-sky-400">focus</span>? ✏️
                </span>
                <span className="box-border inline-block w-1 h-10 ml-2 -mb-2 bg-white  animate-cursor will-change-transform"></span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="relative items-center h-auto w-full m-auto flex flex-col max-w-[550px] aspect-square px-4 ">
        <CircularProgress
          variant="determinate"
          size="100%"
          value={percent}
          thickness={0.7}
          sx={{
            zIndex: 1,
            position: "absolute",
            borderRadius: "100%",
            boxShadow: "inset 0 0 0px 9px rgba(0,0,0,0.10)",
            color: "white",
            backgroundColor: "transparent",
            "& 	.MuiCircularProgress-circle": {
              transition:
                "stroke-dashoffset 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
          }}
        />
        {/*
        {currentTask && (
          <div className={"absolute 	pomoIcon  space-x-2"}>
            <div className={"flex w-full justify-center flex-wrap"}>
              {totalPomoIcons}
            </div>
            <div className={"flex justify-center"}>
              <span className={"text-gray-300"}>
                {currentTask.currentPomo}/{currentTask.pomo} pomodoro done
              </span>
            </div>
          </div>
        )}
        */}
        <div className={classes.timer}>
          {/* Pomodoro option */}
          {option === 0 && (
            <span className="font-semibold text-9xl">
              {minute < 10 ? `0${minute}` : minute}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          )}
          {/* Short Break option */}
          {option === 1 && (
            <span className="font-semibold text-9xl">
              {minute < 10 ? `0${minute}` : minute}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          )}
          {/* Long Break option */}
          {option === 2 && (
            <span className="font-semibold text-9xl">
              {minute < 10 ? `0${minute}` : minute}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          )}
        </div>
        {/* Button Start and Next */}
        <div className="flex gap-6 w-64 mt-10" style={{ zIndex: 2 }}>
          {/* <button
            className={play ? classes.stopButton : classes.startButton}
            onClick={handleStart}
          >
            {play ? "STOP" : "START"}
          </button> */}
          <MusicalNoteIcon
            className="w-28 h-28 cursor-pointer"
            onClick={handlerMusicComponent}
          />
          {settingInfo.isTickingSoundOn && (
            <TimerPlayerComponent
              audioUrl="https://cdn.pixabay.com/audio/2022/03/09/audio_4b7bb9d4c1.mp3"
              play={playRef}
            />
          )}
          {settingInfo.isAlarmSoundOn && (
            <audio
              ref={alarmRef}
              src="https://cdn.pixabay.com/audio/2022/03/19/audio_6492e3e218.mp3"
            />
          )}
          {play ? (
            <PauseIcon onClick={onClickPausePomo} className="cursor-pointer" />
          ) : (
            <PlayIcon onClick={onClickStartPomo} className="cursor-pointer" />
          )}
          <ModalConfirmation ref={modalRef} />
          <ForwardIcon className="w-28 cursor-pointer" onClick={handleNext} />

          {/* <NavigateNextIcon sx={{ fontSize: 40 }} onClick={handleNext} /> */}
        </div>
        {optionRef.current != 0 && (
          <div
            className="flex z-[2] mt-2.5 text-gray-300 cursor-pointer"
            onClick={handleSkipBreak}
          >
            <p>Skip break</p>
          </div>
        )}
        {optionRef.current === 0 && playRef.current && (
          <div
            className="flex z-[2] mt-2.5 text-gray-300 cursor-pointer"
            onClick={handleDoneEarly}
          >
            <p>Done early</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Pomodoro;
