import React, { useContext, useEffect, useRef, useState } from "react";
import { WebsocketContext } from "../../context/WebsocketContext";
import VideoCall from "./VideoCall";
import VideoCallRoom from "./VideoCallRoom";
import ModalCreateRoom from "./ModalCreateRoom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";

const VideoCallPanel = ({ activeTab, setActiveTab }) => {
  const { user } = useContext(AuthContext);
  const socket = useContext(WebsocketContext);
  const [rooms, setRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isInRoom, setIsInRoom] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const modalCreateRoomRef = useRef(null);
  const panelRef = useRef(null);
  useEffect(() => {
    if (activeTab !== "groupVideoCall") return;
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setActiveTab("home");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeTab, setActiveTab]);

  useEffect(() => {
    socket.emit("getActiveRooms");
    socket.on("activeRoomsList", (rooms) => {
      setRooms(rooms);
    });
    socket.on("updatedActiveRooms", (rooms) => {
      setRooms(rooms);
    });
    socket.on("roomCreated", (room) => {
      toast.success(`Created new room: ${room.roomName}`);
      setCurrentRoom(room);
      setIsInRoom(true);
    });
    socket.on("updatedRoom", (room) => {
      if (currentRoom && currentRoom.roomId === room.roomId) {
        console.log("update current room", room);
        setCurrentRoom(room);
      }
    });
    socket.on("error", (err) => {
      toast.error(err.message);
    });
    socket.on("acceptedJoinRoom", (room) => {
      toast.success(`Joined room ${room.roomName}`);
      setCurrentRoom(room);
      setIsInRoom(true);
    });
    return () => {
      socket.off("roomCreated");
      socket.off("updatedActiveRooms");
      socket.off("userJoined");
    };
  }, [socket]);

  const joinRoom = (roomId) => {
    socket.emit("joinRoom", { roomId });
  };
  const handleSearchChange = (e) => {
    console.log("search", e.target.value);
    setSearchTerm(e.target.value);
  };

  const filteredRooms = rooms?.filter((room) =>
    room?.roomName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const leaveRoom = () => {
    if (currentRoom) {
      socket.emit("leaveRoom", { roomId: currentRoom.roomId });
      setCurrentRoom(null);
      setIsInRoom(false);
    }
  };
  const createNewRoomOption = {
    title: "Create new video call room",
    handleCreateNewRoom: (roomName) => {
      socket.emit("createRoom", { roomName });
      modalCreateRoomRef.current.close();
    },
  };
  const handleCreateNewRoom = () => {
    if (!user) {
      toast.error("You must login first to use this feature.");
      return;
    }
    // if (user.subscription === "standard") {
    //   toast.error("Upgrade to premium to create new room.");
    //   return;
    // }
    modalCreateRoomRef.current.open(createNewRoomOption);
  };
  return (
    <div ref={panelRef} className="text-black p-4 w-full flex flex-col">
      {!isInRoom && (
        <>
          <div className="flex flex-col w-full">
            <span className="font-semibold text-xl ">
              Available Study Rooms
            </span>
            <input
              type="text"
              placeholder="Search rooms..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="border-[1px] bg-zinc-50 px-4 py-1 border-gray-300 rounded-lg"
            />
          </div>
          <div className="my-4 w-full bg-gray-200 h-0.5"></div>
          {/* <div className="grid grid-cols-2 md:grid-cols-4 w-full">
            {rooms.length > 0 &&
              rooms.map((room) => (
                <VideoCall
                  room={room}
                  key={room.roomId}
                  onJoinRoom={joinRoom}
                />
              ))}
          </div> */}
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full">
            {user ? (
              filteredRooms.length > 0 ? (
                filteredRooms.map((room) => (
                  <VideoCall
                    room={room}
                    key={room.roomId}
                    onJoinRoom={joinRoom}
                  />
                ))
              ) : (
                <span className="flex text-zinc-500">No rooms found.</span>
              )
            ) : (
              <span className="flex text-zinc-500">
                {" "}
                You need to login to use this feature
              </span>
            )}
          </div>
          <ModalCreateRoom ref={modalCreateRoomRef} />
          {/*<div className="flex flex-col items-center mx-auto mt-auto">*/}
          <button
            className="mt-auto mx-auto bg-sky-300 cursor-pointer hover:bg-sky-400 transform hover:scale-105 transition ease-in-out duration-200 w-fit font-semibold rounded-xl py-2 px-4 text-white"
            onClick={handleCreateNewRoom}
          >
            Create New Room
          </button>
          {/*</div>*/}
        </>
      )}
      {isInRoom && currentRoom && (
        <VideoCallRoom
          room={currentRoom}
          setRoom={setCurrentRoom}
          socket={socket}
          onLeaveRoom={leaveRoom}
        />
      )}
    </div>
  );
};

export default VideoCallPanel;
