import React, { useContext } from "react";
import { CheckIcon, PlusIcon } from "@heroicons/react/20/solid";
import { SettingContext } from "../../context/SettingContext";
import useChangeYoutubeVideo from "../hooks/useChangeYoutubeVideo";

const VideoSearch = ({ detail }) => {
  const settingInfo = useContext(SettingContext);
  const { handleChangeYouTubeVideo } = useChangeYoutubeVideo();
  const {
    id: { videoId },
    kind,
    ...rest
  } = detail;
  const data = { ...rest, id: videoId, kind };
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };
  const handleAddToPlaylist = async (video) => {
    if (settingInfo.youtubeList.length === 0) {
      await handleChangeYouTubeVideo(video);
      settingInfo.setIsPlayYoutubeNow(false);
    }
    const newList = [...settingInfo.youtubeList, video];
    settingInfo.setYoutubeList(newList);
  };
  const handleRemoveFromPlaylist = async (video) => {
    console.log(video);
    const newList = settingInfo.youtubeList.filter(
      (item) => item.id !== video.id
    );
    settingInfo.setYoutubeList(newList);
    if (newList.length === 0) {
      await handleChangeYouTubeVideo(settingInfo.videoYoutubeDefault);
    }
  };
  const handlePlayNow = async () => {
    const newList = [...settingInfo.youtubeList, data];
    settingInfo.setYoutubeList(newList);
    await handleChangeYouTubeVideo(data);
    settingInfo.setIsPlayYoutubeNow(true);
    settingInfo.setPlayNow(true);
    settingInfo.setIsSearchingYoutube(false);
  };

  return (
    <div className="flex relative group/icons ">
      {/*<div className="absolute flex flex-col h-full w-full transition duration-200 ease-in ">*/}
      {/*  <div className="group/tooltip1  z-10  top-1/2 left-[90%] absolute -translate-x-1/2 -translate-y-1/2    "></div>*/}
      {/*  <div className="group/tooltip2 absolute top-1/2 left-2/4 -translate-x-1/2 -translate-y-1/2">*/}
      {/*    <div className="grid  place-items-center">*/}
      {/*      <span className="group-hover/tooltip2:block hidden mb-1 text-gray-900 px-2 py-1 bg-white border border-gray-200 rounded-lg shadow-sm  mb-1 text-sm">*/}
      {/*        Play this*/}
      {/*      </span>*/}
      {/*      <PlayIcon*/}
      {/*        onClick={handlePlayNow}*/}
      {/*        className="cursor-pointer hidden relative group-hover/icons:block opacity-70 hover:opacity-100  text-center w-9 h-9 fill-sky-400 "*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*transition duration-100 ease-in  group-hover/icons:bg-gray-200  group-hover/icons:opacity-30 */}
      <div className="flex gap-2 cursor-default w-full relative   group-hover/icons:opacity-60  ">
        <img
          alt={data.snippet.title}
          onClick={handlePlayNow}
          className=" cursor-pointer rounded-xl "
          src={data.snippet.thumbnails.default.url}
        />

        <div className="flex flex-col text-start w-[340px]	 ">
          <h3
            onClick={handlePlayNow}
            className={"font-semibold titleYoutube cursor-pointer "}
          >
            {decodeHtmlEntities(data?.snippet?.title)}
          </h3>{" "}
          <span className="mt-1 text-sm  text-gray-700 font-normal">
            {data?.snippet?.channelTitle}
          </span>{" "}
          {data.snippet.liveBroadcastContent === "live" && (
            <p className="px-2 text-sm mt-auto mb-2 font-semibold text-white bg-red-400 rounded-2xl w-fit">
              Live
            </p>
          )}
        </div>
        <div className="flex group/tooltip1 h-fit ml-auto pr-2 ">
          {settingInfo?.youtubeList.some((item) => item.id === data.id) ? (
            <>
              <span className="group-hover/tooltip1:block top-[35%] z-10 left-[69%] hidden absolute text-white font-semibold  px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
                Remove this from playlist
              </span>
              <CheckIcon
                onClick={() => handleRemoveFromPlaylist(data)}
                className=" relative  cursor-pointer opacity-70 hover:opacity-100  text-center w-6 h-6 fill-sky-400 mt-1"
              />
            </>
          ) : (
            <>
              <span className="group-hover/tooltip1:block top-[35%] z-10 left-[76%] absolute  hidden text-white font-semibold  px-2 py-1  bg-black rounded-lg shadow-sm   text-xs">
                Add this to playlist
              </span>
              <PlusIcon
                onClick={() => handleAddToPlaylist(data)}
                className=" group-hover/icons:block relative  hidden cursor-pointer opacity-70 hover:opacity-100  text-center mt-1 w-6 h-6 fill-sky-400 "
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoSearch;
