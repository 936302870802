import React, { memo } from "react";
const CategoryStatistic = ({ data }) => {
  return (
    <div className="m-2 p-2 rounded-lg bg-gray-400/20">
      <div className="flex ml-2 font-semibold text-sm select-none">
        <p>Category Distribution</p>
      </div>
      <div className="grid grid-cols-5 mt-2 ml-2 mr-2 text-xs text-zinc-500 font-medium">
        <div className="flex col-span-2 ">
          <p>Category name</p>
        </div>
        <div className="justify-self-end">
          <p>Ratio</p>
        </div>
        <div className="justify-self-end">
          <p>Total time</p>
        </div>
        <div className="justify-center items-center justify-self-end">
          <p>Change</p>
        </div>
      </div>
      {data.length >= 1 ? (
        <>
          {data.map((category, index) => (
            <div key={index} className="ml-2 my-2 mr-2">
              <div className="grid grid-cols-5 text-xs text-black font-medium">
                <div className="flex col-span-2 font-semibold ">
                  {category.name}
                </div>
                <div className="justify-self-end">
                  {Math.round(category.ratio * 100)}%
                </div>
                {category?.time / 60 >= 1 ? (
                  <div className="justify-self-end">
                    {Math.floor(category.time / 60)}h
                    {Math.round((category?.time % 60) * 100) / 100}m
                  </div>
                ) : (
                  <div className="justify-self-end">{category?.time % 60}m</div>
                )}
                {Math.round(category.ratioChange) > 0 ? (
                  <div className="bg-[rgba(5,122,83,.12)]  rounded px-[6px] text-green-500 flex items-center justify-center font-semibold justify-self-end">
                    <p>+{Math.abs(Math.round(category.ratioChange))}%</p>
                  </div>
                ) : (
                  <div className="bg-[rgba(209,4,18,.1215686275)]  rounded px-[6px] text-red-500 flex items-center justify-center font-semibold justify-self-end">
                    <p>-{Math.abs(Math.round(category.ratioChange))}%</p>
                  </div>
                )}
              </div>
              <div className="w-full  bg-zinc-200 rounded-full mt-1.5 h-1 ">
                <div
                  className=" h-1.5 rounded-full "
                  style={{
                    width: `${category.ratio ? category.ratio * 100 : 0}%`,
                    backgroundColor: `${category.color ? category.color : ""}`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="ml-2 my-2 mr-2">
          <div className="grid grid-cols-5 text-xs text-black font-medium">
            <div className="flex col-span-2 font-semibold">Break</div>
            <div className="justify-self-end">-</div>
            <div className="justify-self-end">0m</div>
            <div className="flex items-center  justify-center font-semibold justify-self-end">
              <p>-</p>
            </div>
          </div>
          <div className="w-full  bg-zinc-200 rounded-full mt-1.5 h-1 ">
            <div className=" h-1.5 rounded-full"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CategoryStatistic, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
