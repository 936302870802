import React, { useContext, useEffect, useRef, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ArrowLeftIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { Trash2 } from "lucide-react";
import {
  chatWithPDFAI,
  deletePDF,
  getPDFById,
  getPDFList,
  uploadPDF,
} from "../../api/service";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import CodeRender from "./CodeRender";
import { SettingContext } from "../../context/SettingContext";
import ModalUploadFile from "./ModalUploadFile";

const ChatPDF = ({ activeTab, setActiveTab }) => {
  const queryClient = useQueryClient();
  const { user, isLogin } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [text, setText] = useState("");
  const chatHistoryLS = localStorage.getItem("chatPDFHistory");
  const [chatHistory, setChatHistory] = useState(
    chatHistoryLS ? JSON.parse(chatHistoryLS) : []
  );
  const [selectedChat, setSelectedChat] = useState(null);
  const messagesEndRef = useRef(null);
  const modalUploadFileRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const settingInfo = useContext(SettingContext);
  const [isTyping, setIsTyping] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const defaultMessage = {
    message: "Hi, what do you want to ask about this document?",
    sender: "AI",
  };
  const [messages, setMessages] = useState(
    selectedChat ? selectedChat.messages : [defaultMessage]
  );
  const panelRef = useRef(null);
  useEffect(() => {
    if (activeTab !== "chatPDF") return;
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setActiveTab("home");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeTab, setActiveTab]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // save messages to local storage
    localStorage.setItem("chatPDFHistory", JSON.stringify(chatHistory));
  }, [chatHistory]);

  const uploadPDFMutation = useMutation(
    (file) => {
      return uploadPDF(file);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["documentList"],
          exact: true,
        });
        setIsUploadingFile(false);
        modalUploadFileRef.current.close();
        toast.success("Upload file successfully!");
      },
      onError: (e) => {
        console.log("error", e);
        toast.error(e.message);
        setIsUploadingFile(false);
        modalUploadFileRef.current.close();
      },
    }
  );

  const deletePDFMutation = useMutation(
    (documentId) => {
      return deletePDF(documentId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["documentList"],
          exact: true,
        });
        toast.success("Delete file successfully!");
      },
      onError: (e) => {
        console.log("error", e);
        toast.error(e.message);
      },
    }
  );

  const { isLoading: isLoadingDocumentList, data: documentList } = useQuery(
    ["documentList"],
    () => {
      return getPDFList();
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      enabled: Boolean(isLogin),
      staleTime: Number.POSITIVE_INFINITY,
    }
  );
  const { isLoading: isLoadingDocument, data: documentResponse } = useQuery(
    ["document", selectedChat?._id],
    () => {
      return getPDFById(selectedChat?._id);
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      enabled: Boolean(selectedChat) && Boolean(isLogin),
      staleTime: Number.POSITIVE_INFINITY,
    }
  );
  const documentData = documentResponse ? documentResponse : null;

  const handleSelectChat = (document) => {
    const chat = chatHistory.find((chat) => chat._id === document._id);
    if (chat) {
      setMessages(chat.messages);
      setSelectedChat({ ...chat, messages });
      // setPdfUrl(documentData?.documentUrl);
      // setPdfUrl(document.documentUrl);
    } else {
      setSelectedChat({ ...document, messages: [defaultMessage] });
      // setPdfUrl(document.documentUrl);
      // setPdfUrl(documentData?.documentUrl);
      setMessages([defaultMessage]);
    }
  };
  const handleBackToChatList = () => {
    setSelectedChat(null);
    setPdfUrl("");
    setMessages([defaultMessage]);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleSendMessage = async (message) => {
    setText("");
    if (!validateUser()) return;
    if (!selectedChat) {
      toast.error("Please select a document to chat");
    }
    if (!message) {
      toast.error("Please enter a message");
    }
    const newMessage = {
      message,
      sender: "user",
    };
    setMessages([...messages, newMessage]);
    setIsTyping(true);
    await processUserQuery([...messages, newMessage]);
  };
  const chatWithPDFMutation = useMutation(
    (param) => {
      return chatWithPDFAI(param?.query, param?.documentId);
    },
    {
      onError: (e) => {
        toast.error(e.message);
        setIsTyping(false);
      },
    }
  );
  const processUserQuery = async (messages) => {
    const userQuery = messages[messages.length - 1].message;
    const data = await chatWithPDFMutation.mutateAsync({
      query: userQuery,
      documentId: selectedChat?._id,
    });
    if (data?.response) {
      const newMessage = {
        message: data.response,
        sender: "AI",
      };
      setMessages([...messages, newMessage]);
      const updateSelectedChat = {
        ...selectedChat,
        messages: [...messages, newMessage],
      };
      setSelectedChat(updateSelectedChat);
      const updateChatHistory = chatHistory.map((chat) => {
        if (chat._id === selectedChat._id) {
          return updateSelectedChat;
        }
        return chat;
      });
      if (!chatHistory.find((chat) => chat._id === selectedChat._id)) {
        updateChatHistory.push(updateSelectedChat);
      }
      setChatHistory(updateChatHistory);
      setIsTyping(false);
    } else {
      setIsTyping(false);
      toast.error("Error while processing your query");
    }
  };

  const UploadFileOption = {
    title: "Upload your PDF",
    handleUploadFile: async (file) => {
      console.log("click upload ", file);
      if (validateUser()) {
        setIsUploadingFile(true);
        await uploadPDFMutation.mutate(file);
      } else return;
    },
  };
  const handleClickNewPDFChat = () => {
    console.log("open modal");
    modalUploadFileRef.current.open(UploadFileOption);
  };

  const handleDeleteDocument = async (documentId) => {
    console.log("delete document", documentId);
    await deletePDFMutation.mutate(documentId);
    const updateChatHistory = chatHistory.filter(
      (chat) => chat._id !== documentId
    );
    setChatHistory(updateChatHistory);
    setSelectedChat(null);
    setMessages([defaultMessage]);
  };

  const validateUser = () => {
    if (!isLogin) {
      toast.error("You need to login to use this feature");
      return false;
    }
    if (isLogin && user.subscription !== "premium") {
      toast.error("Upgrade to premium to use this feature");
      return false;
    }
    return true;
  };
  return (
    <div ref={panelRef} className="text-black flex flex-row w-full h-full">
      {!selectedChat ? (
        <div className="flex flex-col w-1/2 overflow-y-scroll">
          <div className="flex flex-row items-center justify-center p-2">
            <button
              className="cursor-pointer font-semibold bg-sky-400 transform hover:scale-105 transition ease-in-out duration-200  ml-auto hover:bg-sky-500 p-2 rounded-lg text-white w-max-[64px] w-fit self-center justify-center"
              onClick={handleClickNewPDFChat}
            >
              New PDF chat
            </button>
            <div className="ml-auto">{/*<PopUp options={listOptions} />*/}</div>
          </div>
          <div className="w-full overflow-auto flex flex-col space-y-0.5">
            <>
              <div className="mt-2 w-full bg-gray-200 h-0.5"></div>
              {!isLogin && (
                <span className="flex text-zinc-500 ml-4">
                  You need to login to use this feature
                </span>
              )}
              {isLogin && isLoadingDocumentList && <span>Loading...</span>}
              {!isLoadingDocumentList &&
                documentList.length > 0 &&
                documentList.map((document, index) => {
                  return (
                    <div
                      key={index}
                      className="flex border-[1px] rounded border-gray-200 mx-1 w-full bg-zinc-100 hover:bg-zinc-200 flex-row p-2 items-center"
                    >
                      <div
                        onClick={() => handleSelectChat(document)}
                        className="flex cursor-pointer flex-row items-center space-x-1 w-[90%]"
                      >
                        <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                          {document?.documentOriginalName}
                        </span>
                      </div>
                      <div className="cursor-pointer">
                        <Trash2
                          className="w-[20px] h-[20px] z-20 hover:text-sky-400 text-zinc-600"
                          onClick={() => handleDeleteDocument(document?._id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </div>
      ) : (
        <div className="w-1/2 overflow-y-auto flex flex-col">
          <div className="flex items-center p-4">
            <ArrowLeftIcon
              className="w-6 h-6 mr-2 cursor-pointer text-sky-400"
              onClick={handleBackToChatList}
            />
            <span className="font-semibold mx-auto text-lg break-all">
              {selectedChat?.documentOriginalName || "Selected document"}
            </span>
          </div>
          {isLoadingDocument && <span>Loading...</span>}
          {!isLoadingDocument && selectedChat && documentData && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                className="rpv-core__viewer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                  }}
                >
                  <Viewer
                    fileUrl={documentData?.documentUrl}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              </div>
            </Worker>
          )}
        </div>
      )}
      <ModalUploadFile ref={modalUploadFileRef} isUploading={isUploadingFile} />
      <div className="mx-1 h-full bg-zinc-200 w-[2px]"></div>
      <div className="w-1/2 h-full">
        <div className="flex flex-col h-full">
          <div className="flex w-full invisible">
            <span className="text-ellipsis whitespace-nowrap overflow-hidden">
              {(selectedChat &&
                documentData &&
                selectedChat?.documentOriginalName) ||
                "Selected document"}
            </span>
          </div>
          <div className="p-4 message-list space-y-2 flex flex-col flex-1 overflow-y-scroll">
            {messages?.map((message, index) => {
              return message.sender === "AI" ? (
                <div key={index} className="flex space-x-2 justify-start">
                  <img
                    src="/favicon.ico"
                    alt="stayinpomo-logo"
                    className="w-10 h-10 rounded-full"
                  />
                  <CodeRender
                    key={index}
                    response={message.message}
                    indexMsg={index}
                  />
                  {/* <div className="bg-zinc-400 text-start text-white rounded-lg p-2">
                  {message.message}
                </div> */}
                </div>
              ) : (
                <div key={index} className="flex space-x-2 justify-end">
                  <div className="bg-blue-400 text-start text-white rounded-lg py-2 px-3">
                    {message.message}
                  </div>
                  <img
                    alt="user-profile"
                    referrerPolicy="no-referrer"
                    className="w-8 h-8 rounded-full"
                    src={
                      user?.picture
                        ? user?.picture
                        : settingInfo.googleImgDefault
                    }
                  />
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-4 flex flex-col w-full mt-2">
            {isTyping && (
              <div className="text-zinc-500 flex font-semibold mb-1">
                AI is typing
                <span className="animate-pulse">...</span>
              </div>
            )}
            <div className="flex flex-row mt-auto items-center space-x-2">
              <input
                type="text"
                // defaultValue={text}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    await handleSendMessage(text);
                  }
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-2 focus:ring-sky-300 block w-full p-2.5"
                placeholder="Chat with AI here"
              />
              <PaperAirplaneIcon
                className="cursor-pointer text-sky-400 w-6 h-6"
                onClick={() => handleSendMessage(text)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPDF;
