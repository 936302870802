import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/20/solid";
import CategoryChart from "./Charts/CategoryChart";
import { memo, useEffect, useState } from "react";
import { days, handleGenerateXAsisDays, workTime } from "../../common/global";
import DailyChart from "./Charts/DailyChart";
import CategoryStatistic from "./Charts/CategoryStatistic";
import CountUp from "react-countup";
import StatisticNumber from "./StatisticNumber";
import LineChart from "./Charts/LineChart";

const Statistic = ({ statisticData, sessionsGroupedByMonth, diffStats }) => {
  let categoryData = { labels: [], colors: [], series: [] };

  const [totalPomos, setTotalPomos] = useState(
    statisticData?.data ? statisticData.data.totalPomos : 0
  );
  const [totalHours, setTotalHours] = useState(
    statisticData?.data ? statisticData.data.totalPomoTime / 60 : 0
  );
  const [totalMinutes, setTotalMinutes] = useState(
    statisticData?.data ? statisticData.data.totalPomoTime % 60 : 0
  );
  const [totalBreakHours, setTotalBreakHours] = useState(
    statisticData?.data ? statisticData.data.totalBreakTime / 60 : 0
  );
  const [totalBreakMinutes, setTotalBreakMinutes] = useState(
    statisticData?.data ? statisticData.data.totalBreakTime % 60 : 0
  );
  const [hourAvgDay, setHourAvgDay] = useState(
    statisticData?.data
      ? statisticData.data.totalPomoTime /
          60 /
          statisticData?.data?.workDaysInMonth.length
      : 0
  );
  const [minuteAvgDay, setMinuteAvgDay] = useState(
    statisticData?.data
      ? (statisticData.data.totalPomoTime % 60) /
          statisticData?.data?.workDaysInMonth.length
      : 0
  );
  const [bestWorkday, setBestWorkday] = useState(
    statisticData?.data
      ? days[Math.round(statisticData?.data?.bestWorkDay)]
      : "--"
  );

  const [bestWorkTime, setBestWorkTime] = useState(
    statisticData?.data
      ? workTime[Math.round(statisticData.data.bestWorkTime)]
      : "--"
  );
  const xAsisDays = handleGenerateXAsisDays(
    statisticData?.date?.month,
    statisticData?.date?.year
  );

  if (sessionsGroupedByMonth.length) {
    sessionsGroupedByMonth.sort((a, b) => b.timeOnCategory - a.timeOnCategory);
    sessionsGroupedByMonth.map((item) => {
      {
        categoryData.labels.push(item.name);
        categoryData.colors.push(item.color);
        categoryData.series.push(item.timeOnCategory);
      }
    });
  }

  useEffect(() => {
    setTotalPomos(statisticData?.data ? statisticData.data.totalPomos : 0);
    setTotalHours(
      statisticData?.data ? statisticData.data.totalPomoTime / 60 : 0
    );
    setTotalMinutes(
      statisticData?.data ? statisticData.data.totalPomoTime % 60 : 0
    );
    setTotalBreakHours(
      statisticData?.data ? statisticData.data.totalBreakTime / 60 : 0
    );
    setTotalBreakMinutes(
      statisticData?.data ? statisticData.data.totalBreakTime % 60 : 0
    );
    setHourAvgDay(
      statisticData?.data
        ? statisticData.data.totalPomoTime /
            60 /
            statisticData?.data?.workDaysInMonth.length
        : 0
    );
    setMinuteAvgDay(
      statisticData?.data
        ? (statisticData.data.totalPomoTime % 60) /
            statisticData?.data?.workDaysInMonth.length
        : 0
    );
    setBestWorkday(
      statisticData?.data
        ? days[Math.round(statisticData?.data?.bestWorkDay)]
        : "--"
    );
    setBestWorkTime(
      statisticData?.data
        ? workTime[Math.round(statisticData.data.bestWorkTime)]
        : "--"
    );
  }, [statisticData, sessionsGroupedByMonth, diffStats]);
  const focusedTimes = statisticData?.data?.sessions.reduce(
    (count, session) => {
      if (session.sessionTitle !== "" && session?.disruptedNumber === 0) {
        return count + 1;
      } else {
        return count;
      }
    },
    0
  );
  return (
    <div className="overflow-auto max-h-screen">
      <StatisticNumber
        diffStats={diffStats}
        totalPomos={totalPomos}
        totalHours={totalHours}
        totalMinutes={totalMinutes}
        totalBreakHours={totalBreakHours}
        totalBreakMinutes={totalBreakMinutes}
        hourAvgDay={hourAvgDay}
        minuteAvgDay={minuteAvgDay}
        bestWorkTime={bestWorkTime}
        bestWorkday={bestWorkday}
      />
      <div className="grid  grid-cols-2 bg-gray-400/20 m-2 rounded p-4">
        <div className="grid">
          <span className="font-semibold text-gray-600">100% focused 🧠</span>
          <div className="text-green-500 text-sm flex justify-center items-center bg-[rgba(5,122,83,.12)] w-fit mx-auto px-[6px] rounded font-semibold">
            <span>{focusedTimes ?? 0} sessions</span> &nbsp;💪
          </div>
        </div>
        <div className="grid">
          <span className="font-semibold text-gray-600">Disrupted 💢</span>
          <div className="text-red-500 text-sm flex justify-center items-center bg-[rgba(209,4,18,.1215686275)] w-fit mx-auto px-[6px] rounded font-semibold">
            <span>{statisticData?.data?.totalDisruptedNumber ?? 0} times</span>
            &nbsp;⛔
          </div>
        </div>
      </div>
      <LineChart data={sessionsGroupedByMonth} />
      {/* <HeatmapChart data={[]} fromDate={"2024-04-01"} toDate={"2024-04-30"} /> */}
      <DailyChart data={sessionsGroupedByMonth} categoriesAxis={xAsisDays} />
      <CategoryChart data={categoryData} />
      <CategoryStatistic data={diffStats?.timeOnCategoryDifference ?? []} />
    </div>
  );
};
export default memo(Statistic, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
