import React, { createContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserProfile, logOut } from "../api/service";

export const AuthContext = createContext();
export default function AuthProvider({ children }) {
  const { data: user, isSuccess: isLoginSuccess } = useQuery(
    ["profile"],
    getUserProfile,
    {
      staleTime: 1000 * 60 * 60,
      retry: 3,
    }
  );
  const queryClient = useQueryClient();
  const { mutate } = useMutation(logOut, {
    onSuccess: () => {
      // delete categories in LS
      localStorage.removeItem("categories");
      queryClient.clear();
    },
  });

  return (
    <AuthContext.Provider
      value={{ user, isLogin: Boolean(user), logOut: mutate }}
    >
      {children}
    </AuthContext.Provider>
  );
}
