import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import VideoDetail from "./VideoDetail";
import { getVideoYoutube } from "../../api/service";
import {
  ArchiveBoxXMarkIcon,
  ArrowLeftIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { PictureInPicture } from "lucide-react";
import VideoPlaylist from "./VideoPlaylist";
import SearchList from "./SearchList";
import { SettingContext } from "../../context/SettingContext";
import PopUp from "../common/PopUp";
import useChangeYoutubeVideo from "../hooks/useChangeYoutubeVideo";
import ModalListOptions from "../common/ModalListOptions";
import ModalCreatePlaylist from "./ModalCreatePlaylist";
import { toast } from "react-toastify";
import {
  encryptAndStoreData,
  retrieveAndDecryptData,
} from "../common/encryptedStorage";
import ModalChoosePlaylist from "./ModalChoosePlaylist";
import ModalConfirmation from "../common/ModalConfirmation";

const Music = ({ openMusicComponent, setOpenMusicComponent }) => {
  const [searchYoutube, setSearchYoutube] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const { handleChangeYouTubeVideo } = useChangeYoutubeVideo();
  const settingInfo = useContext(SettingContext);
  const cachedResults = useRef({});
  const MAX_CACHE_SIZE = 10;
  const CACHE_DURATION = 3 * 60 * 60 * 1000;
  const modalListOptionsRef = useRef(null);
  const modalCreatePlaylistRef = useRef(null);
  const modalChoosePlaylistRef = useRef(null);
  const modalConfirmationRef = useRef(null);
  const listOptions = [
    {
      name: "Save playlist",
      handle: () => {
        handleClickSaveAsPlaylist();
      },
      icon: FolderPlusIcon,
    },
    {
      name: "Choose playlist",
      handle: () => {
        handleClickChoosePlaylist();
      },
      icon: PlusIcon,
    },
    {
      name: "Remove all videos",
      handle: () => {
        handleRemoveAllVideosInList();
      },
      icon: ArchiveBoxXMarkIcon,
    },
  ];
  const SaveVideoListAsPlaylistOption = {
    title: "Save videos as playlist",
    handleSavePlaylist: (playlistName) => {
      if (isPlaylistNameValid(playlistName)) {
        handleSaveAsPlaylist(playlistName, settingInfo.youtubeList);
        toast.success(`Saved playlist: ${playlistName}`);
        modalCreatePlaylistRef.current.close();
      } else {
        toast.error("Playlist name is used, please change.");
      }
    },
  };
  const ChoosePlaylistOption = {
    title: "Choose playlist",
    handleChoosePlaylist: (playlistName) => {
      handleChoosePlaylistToListen(playlistName);
      modalChoosePlaylistRef.current.close();
    },
    handleRemovePlaylist: (playlistName) => {
      handleRemovePlaylist(playlistName);
    },
    playlists: retrieveAndDecryptData("youtube-playlist"),
  };

  const handleRemoveAllVideosInList = async () => {
    await handleChangeYouTubeVideo(settingInfo.videoYoutubeDefault);
    settingInfo.setYoutubeList([settingInfo.videoYoutubeDefault]);
  };
  const isPlaylistNameValid = (playlistName) => {
    const playlists = retrieveAndDecryptData("youtube-playlist");
    if (playlists) {
      const playlist = playlists.find(
        (item) => item.playlistName === playlistName
      );
      if (playlist) {
        return false;
      }
    }
    return true;
  };
  const handleClickSaveAsPlaylist = () => {
    modalCreatePlaylistRef.current.open(SaveVideoListAsPlaylistOption);
  };
  const handleClickChoosePlaylist = (playlistName) => {
    modalChoosePlaylistRef.current.open(ChoosePlaylistOption);
  };
  const handleRemovePlaylist = (playlistName) => {
    const confirmDeletePlaylistOption = {
      title: `Are you sure want to delete playlist ${playlistName}?`,
      content: "We can't recover it after you delete.",
      handleConfirm: () => {
        const playlists = retrieveAndDecryptData("youtube-playlist");
        const newPlaylists = playlists.filter(
          (item) => item.playlistName !== playlistName
        );
        encryptAndStoreData("youtube-playlist", newPlaylists);
        modalConfirmationRef.current.close();
        modalChoosePlaylistRef.current.close();
        ChoosePlaylistOption.playlists = newPlaylists;
        toast.success(`Deleted playlist: ${playlistName}!`);
      },
      handleCancel: () => {
        modalConfirmationRef.current.close();
      },
      confirm: "Delete",
      cancel: "Cancel",
    };
    modalConfirmationRef.current.open(confirmDeletePlaylistOption);
  };
  const handleSaveAsPlaylist = async (playlistName, playlist) => {
    let existingPlaylists = retrieveAndDecryptData("youtube-playlist");
    existingPlaylists = existingPlaylists ? existingPlaylists : [];
    existingPlaylists.push({ playlistName, playlist });
    encryptAndStoreData("youtube-playlist", existingPlaylists);
  };

  const handleChoosePlaylistToListen = async (playlistName) => {
    const playlists = retrieveAndDecryptData("youtube-playlist");
    const playlist = playlists.find(
      (item) => item.playlistName === playlistName
    );
    settingInfo.setYoutubeList(playlist.playlist);
    await handleChangeYouTubeVideo(playlist.playlist[0]);
  };

  // TODO: save into local storage instead
  const handleSearchYoutube = useCallback(async (keyword) => {
    if (keyword == null || keyword.length == 0) {
      return;
    }
    if (
      cachedResults.current[keyword] &&
      Date.now() - cachedResults.current[keyword].timestamp < CACHE_DURATION
    ) {
      setSearchResults(cachedResults.current[keyword].data);
      settingInfo.setIsSearchingYoutube(true);
    } else {
      const response = await getVideoYoutube(keyword);
      setSearchResults(response);
      cachedResults.current[keyword] = {
        data: response,
        timestamp: Date.now(),
      };
      // check cache size and clear out oldest items if necessary
      const keys = Object.keys(cachedResults.current);
      if (keys.length > MAX_CACHE_SIZE) {
        let oldestKey = keys[0];
        for (let i = 1; i < keys.length; i++) {
          if (
            cachedResults.current[keys[i]].timestamp <
            cachedResults.current[oldestKey].timestamp
          ) {
            oldestKey = keys[i];
          }
        }
        delete cachedResults.current[oldestKey];
      }
      settingInfo.setIsSearchingYoutube(true);
    }
  }, []);

  const memoizedHandleSearchYoutube = useMemo(
    () => handleSearchYoutube,
    [handleSearchYoutube]
  );

  const handleBackToMusicComponent = () => {
    settingInfo.setIsSearchingYoutube(false);
  };
  const handleOpenPopUpYoutube = () => {
    // turn off youtube
    settingInfo.setIsPlayYoutubeNow(false);
    settingInfo.youtubeRef.current.internalPlayer.pauseVideo();
    settingInfo.setOpenMusicComponent(false);
    settingInfo.youtubeRef.current.internalPlayer
      .getCurrentTime()
      .then((result) => {
        settingInfo.setCurrentTimeOfYoutube(result);
      });
    settingInfo.youtubeRef.current.internalPlayer.pauseVideo();
    // turn on popup youtube
    settingInfo.setOpenPopUpYoutube(true);
    settingInfo.setIsPlayPopUpYoutubeNow(true);
  };

  return (
    <div
      className={
        openMusicComponent
          ? "w-full h-full fixed inset-0 z-10  hidden md:block"
          : "w-full h-full fixed inset-0 invisible z-10  hidden md:block"
      }
    >
      <div
        onClick={() => {
          setOpenMusicComponent(false);
        }}
        className={
          openMusicComponent
            ? "w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-700 opacity-50"
            : "w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-700 " +
              " opacity-0"
        }
      ></div>
      <div
        id="slideover"
        className={
          openMusicComponent
            ? " w-screen max-w-xl  bg-white h-full absolute left-0 duration-700 ease-in-out translate-x-0 transition-all"
            : " w-screen max-w-xl bg-white h-full absolute left-0 duration-700 ease-in-out transition-all -translate-x-full"
        }
      >
        <div className="flex relative h-full flex-col overflow-y-scroll overflow-x-hidden bg-white py-6 shadow-xl">
          <div className="px-6 flex items-center ">
            <div className="text-2xl font-semibold text-sky-400 ">Music</div>
            <PictureInPicture
              onClick={handleOpenPopUpYoutube}
              className="ml-auto cursor-pointer text-sky-400  w-6 h-6"
            />
          </div>
          <div className="relative text-black mt-6 flex-1 px-6">
            <div className="flex items-center space-x-2 w-full">
              {settingInfo.isSearchingYoutube && (
                <ArrowLeftIcon
                  className="w-6 h-6 cursor-pointer text-sky-400"
                  onClick={handleBackToMusicComponent}
                />
              )}
              <input
                type="text"
                id="taskName"
                autoFocus={true}
                defaultValue={""}
                onInput={(e) => setSearchYoutube(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    memoizedHandleSearchYoutube(searchYoutube);
                  }
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-2 focus:ring-sky-300 block w-full p-2.5 "
                placeholder="What do you want to listen?"
                required
              />

              <MagnifyingGlassIcon
                onClick={() => memoizedHandleSearchYoutube(searchYoutube)}
                className="cursor-pointer text-sky-400  w-6 h-6"
              />
            </div>
            <div
              className={`transition absolute pr-12 overflow-hidden w-full duration-700`}
              //   isSearching ? "-translate-x-full invisible" : "translate-x-0"
              style={{
                transform: settingInfo.isSearchingYoutube
                  ? "translateX(-150%)"
                  : "translateX(0%)",
              }}
            >
              <VideoDetail
                video={settingInfo.currentYoutube}
                channel={
                  settingInfo.currentChannel ? settingInfo.currentChannel : null
                }
              />
              {settingInfo.youtubeList.length > 0 &&
              !settingInfo.isLoadingVideo ? (
                <div className="max-h-[580px] min-h-[220px]  overflow-y-scroll space-y-3 border-solid border flex flex-col border-gray-200 rounded-xl px-4 py-2">
                  <div className="flex justify-between items-center ">
                    <p className=" cursor-default text-center font-medium text-md  text-sky-400">
                      Playlist
                    </p>
                    <PopUp options={listOptions} />
                    {/*<EllipsisVerticalIcon className="cursor-pointer w-6 h-6 fill-sky-400 hover:opacity-100 opacity-70" />*/}
                  </div>

                  <VideoPlaylist youtubeList={settingInfo.youtubeList} />
                </div>
              ) : null}
            </div>
            <ModalListOptions ref={modalListOptionsRef} />
            <ModalCreatePlaylist ref={modalCreatePlaylistRef} />
            <ModalChoosePlaylist ref={modalChoosePlaylistRef} />
            <ModalConfirmation ref={modalConfirmationRef} />
            <div
              className={`transition mt-4 duration-700`}
              style={{
                transform: settingInfo.isSearchingYoutube
                  ? ""
                  : "translateX(150%)",
              }}
              aria-hidden={!settingInfo.isSearchingYoutube}
            >
              <SearchList responseList={searchResults} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Music, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
