import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/index.css";
import AuthProvider from "./context/AuthContext";
import SettingProvider from "./context/SettingContext";
import { BrowserRouter } from "react-router-dom";
import WebsocketProvider from "./context/WebsocketContext";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      isEmailRequired: true,
    }),
  ],
  maxBreadcrumbs: 50,

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost:3000",
    "localhost:500",
    "localhost",
    process.env.REACT_APP_BE_URL_DEV,
    process.env.REACT_APP_FE_URL_DEV,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
});

const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <SettingProvider>
            <WebsocketProvider>
              <App />
            </WebsocketProvider>
          </SettingProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
