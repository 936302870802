import { Divider } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SettingContext } from "../../context/SettingContext";
import { LogOut } from "lucide-react";

const Header = () => {
  const settingInfo = useContext(SettingContext);
  const { isLogin, logOut } = useContext(AuthContext);
  const [showProfile, setShowProfile] = useState(false);
  // const provider = new GoogleAuthProvider();
  const { user } = useContext(AuthContext);
  const profileRef = useRef(null);

  //click outside
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowProfile(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(profileRef);
  // const checkUserExist = (additionalUserInfo, user) => {
  //     if (additionalUserInfo?.isNewUser) {
  //         addUser(user.uid, {
  //             uid: user.uid,
  //             displayName: user.displayName,
  //             photoURL: user.photoURL,
  //             email: user.email,
  //         });
  //     }
  // };

  const handleLogin = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_BE_URL_DEV}/api/auth/google`,

        "_self",
        "width: 452,height: 633 "
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="w-full px-6">
      <div className="flex justify-between h-[60px] w-full pt-2 items-center m-auto">
        <div className="text-xl font-semibold cursor-pointer  transform  hover:scale-110 transition duration-200 ">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            className="absolute top-3/4 left-0 h-[0.68em] w-full fill-sky-400"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
          </svg>
          <span className=" transform transition duration-800 sticky ">
            stayin
          </span>
          <span className="text-xl font-semibold text-sky-400 cursor-pointer">
            pomo
          </span>
        </div>

        <div className="flex items-center">
          <button
            className=" text-white  fill-white  text-base font-semibold cursor-pointer flex hover:scale-110 transition duration-200 items-center hover:fill-sky-400 hover:text-sky-400 mr-2"
            onClick={() => settingInfo.setShowReport(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm4.5 7.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0v4.5a.75.75 0 001.5 0V12zm2.25-3a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0V9.75A.75.75 0 0113.5 9zm3.75-1.5a.75.75 0 00-1.5 0v9a.75.75 0 001.5 0v-9z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base ml-1  font-semibold  cursor-pointer">
              Analytic
            </span>
          </button>
          <button
            className=" text-white text-base font-semibold hover:scale-110 transition duration-200 fill-white cursor-pointer flex items-center hover:fill-sky-400 hover:text-sky-400 mr-2 "
            onClick={() => {
              settingInfo.setShowSetting(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-6 h-6"
            >
              <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
            </svg>

            <span className="text-base font-semibold ml-1 cursor-pointer">
              Setting
            </span>
          </button>

          {isLogin ? (
            <>
              <div className="relative">
                <div
                  className="cursor-pointer"
                  onClick={() => setShowProfile((prev) => !prev)}
                >
                  <img
                    alt="user-profile"
                    referrerPolicy="no-referrer"
                    src={
                      user.picture ? user.picture : settingInfo.googleImgDefault
                    }
                    className="rounded-full w-8 h-8 object-cover cursor-pointer"
                  />
                </div>
                {showProfile && (
                  <div
                    className="z-30 rounded-lg opacity-100 block absolute bg-white min-w-[200px] w-fit transform translate-y-105 right-0"
                    ref={profileRef}
                  >
                    <div
                      className="text-black rounded-lg flex flex-row space-x-2 px-4 py-2 cursor-pointer hover:bg-gray-200 items-center"
                      onClick={() => {
                        logOut();
                        setShowProfile((prev) => !prev);
                      }}
                    >
                      <LogOut className="w-5 h-5" />
                      <span>Log out</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <button
              className="text-white fill-white transform  hover:scale-110 transition duration-200 text-base font-semibold cursor-pointer flex items-center hover:fill-sky-400 hover:text-sky-400"
              onClick={handleLogin}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-semibold cursor-pointer ml-1">
                Login
              </span>
            </button>
          )}
        </div>
      </div>
      <Divider sx={{ color: "white", borderColor: "rgb(214 204 204 / 25%)" }} />
    </div>
  );
};

export default Header;
