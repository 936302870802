import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, EllipsisVerticalIcon } from "@heroicons/react/24/solid";

const PopUp = ({ options }) => {
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500 ",
              "group inline-flex items-center rounded-md focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2"
            )}
          >
            <EllipsisVerticalIcon
              className={classNames(
                open ? "text-sky-400" : "text-sky-300",
                " h-6 w-6 opacity-70 group-hover:opacity-100"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 mt-2 w-screen max-w-[250px] transform px-2 ml-2 -translate-x-full">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-sky-400 ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-4 py-5 ">
                  {options.map((item) => (
                    <Popover.Button
                      key={item.name}
                      className={classNames(
                        open
                          ? "text-gray-900"
                          : "text-gray-500" +
                              "group inline-flex items-center rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 "
                      )}
                    >
                      <a
                        className={`${
                          item.focused ? "bg-zinc-100" : " "
                        } -m-3 flex items-start rounded-lg p-3 hover:bg-zinc-200 cursor-pointer`}
                        onClick={() => item.handle()}
                      >
                        <item.icon
                          className="h-6 w-6 flex-shrink-0 text-sky-400"
                          aria-hidden="true"
                        />

                        <div className="ml-4">
                          <p className="text-sm font-normal text-black">
                            {item.name}
                          </p>
                        </div>
                        {item.focused && (
                          <CheckIcon
                            className="h-5 w-5 text-sky-400 ml-auto"
                            aria-hidden="true"
                          />
                        )}
                      </a>
                    </Popover.Button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopUp;
