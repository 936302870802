import React, { useContext, useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "@tanstack/react-query";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { Minimize2 } from "lucide-react";

import Pomodoro from "./Pomo";
import Header from "./Header";
import { SettingContext } from "../context/SettingContext";
import SettingPanel from "./Header/Setting/SettingPanel";
import Report from "./Header/Report/Report";
import { AuthContext } from "../context/AuthContext";
import YoutubeVideo from "./common/YoutubeVideo";
import YoutubeButton from "./common/YoutubeButton";
import {
  getDefaultChannel,
  getDefaultVideo,
  getSessionsGroupedByMonth,
} from "../api/service";
import Music from "./Music/Music";
import TaskFormSlide from "./TaskList/TaskFormSlide";
import { retrieveAndDecryptData } from "./common/encryptedStorage";
import { defaultDatePicker } from "./common/global";
import { Toolbar } from "./Toolbar";
import VideoCallPanel from "./StudyTogether/VideoCallPanel";
import { Slide, toast, ToastContainer } from "react-toastify";
import Chat from "./Chat/Chat";
import WarningDialog from "./common/ModalWarning";
import ChatPDF from "./Chat/ChatPDF";

let currentUserSessions = retrieveAndDecryptData("current-local-sessions");
const HomePage = () => {
  const settingInfo = useContext(SettingContext);
  const [tasks, setTasks] = useState(currentUserSessions ?? []);
  // const storage = JSON.parse(localStorage.getItem("default"));
  const currentBackground = settingInfo.pomoBackground
    ? 0
    : settingInfo.shortBackground
    ? 1
    : 2;
  const { isLogin } = useContext(AuthContext);
  const taskFormRef = useRef(null);
  const [draggablePosition, setDraggablePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const draggableRef = useRef(null);
  const [activeTab, setActiveTab] = useState("home");

  const showHome = () => setActiveTab("home");
  const showGroupVideoCall = () => setActiveTab("groupVideoCall");
  const showChat = () => setActiveTab("chat");
  const showChatPDF = () => setActiveTab("chatPDF");

  const { isLoading, data: sessionsGroupedByMonth } = useQuery(
    ["user-sessions-by-month"],
    () => getSessionsGroupedByMonth(),
    {
      onSuccess: (userSessions) => {
        settingInfo.setAllCurrentUserSessions(userSessions);
      },
      onError: () => {
        toast.error("Failed to get user sessions data");
      },
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(isLogin),
    }
  );
  const { youtubeData } = useQuery(
    ["default-video"],
    () => Promise.all([getDefaultVideo(), getDefaultChannel()]),
    {
      onSuccess: (res) => {
        settingInfo.setCurrentYoutube(res ? res[0].items[0] : null);
        settingInfo.setCurrentChannel(res ? res[1].items[0] : null);
      },
      onError: () => {},
      staleTime: Number.POSITIVE_INFINITY,
      retry: 1,
    }
  );
  let userSessions =
    sessionsGroupedByMonth && sessionsGroupedByMonth.length
      ? sessionsGroupedByMonth
      : defaultDatePicker();
  const handleOpenTaskForm = () => {
    taskFormRef.current.open();
  };
  const handleOpenMusicComponent = () => {
    settingInfo.setOpenMusicComponent(true);
    settingInfo.setOpenPopUpYoutube(false);
    settingInfo.setIsPlayPopUpYoutubeNow(false);
    const youtubeRef = settingInfo.youtubeRef;
    if (
      settingInfo.currentTimeOfYoutube !== 0 &&
      settingInfo.popUpYoutubeRef.current
    ) {
      settingInfo.popUpYoutubeRef.current.internalPlayer
        .getCurrentTime()
        .then((res) => {
          settingInfo.setCurrentTimeOfYoutube(res);
          youtubeRef.current.internalPlayer.seekTo(res);
        });
      if (settingInfo.playNow) {
        settingInfo.popUpYoutubeRef.current.internalPlayer.pauseVideo();
        settingInfo.setIsPlayYoutubeNow(true);
      } else {
        settingInfo.popUpYoutubeRef.current.internalPlayer.pauseVideo();
        settingInfo.setIsPlayYoutubeNow(false);
      }
    }
  };

  const handleOpenPopUpYoutubeVideo = () => {
    if (!isDragging) {
      setDraggablePosition({
        x: draggablePosition.x,
        y: draggablePosition.y,
      });
      const youtubeRef = settingInfo.youtubeRef;
      const popUpYoutubeRef = settingInfo.popUpYoutubeRef;
      if (!settingInfo.isPlayYoutubeNow) {
        popUpYoutubeRef.current.internalPlayer.getCurrentTime().then((res) => {
          settingInfo.setCurrentTimeOfYoutube(res);
          popUpYoutubeRef.current.internalPlayer.seekTo(res);
        });
        settingInfo.setOpenPopUpYoutube(true);
        settingInfo.setIsPlayPopUpYoutubeNow(true);

        return;
      }
      youtubeRef.current.internalPlayer.getCurrentTime().then((res) => {
        settingInfo.setCurrentTimeOfYoutube(res);
        popUpYoutubeRef.current.internalPlayer.seekTo(res);
      });
      youtubeRef.current.internalPlayer.pauseVideo();
      settingInfo.setIsPlayYoutubeNow(false);
      settingInfo.setOpenPopUpYoutube(true);
      settingInfo.setIsPlayPopUpYoutubeNow(true);
      settingInfo.setOpenMusicComponent(false); // close music component
    }
  };
  const handleMinimizePopUpYoutube = () => {
    if (!isDragging) {
      settingInfo.setOpenPopUpYoutube(false);
      setDraggablePosition({
        x: draggablePosition.x,
        y: draggablePosition.y,
      });
    }
  };
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;
  if (isSupported() && Notification.permission !== "granted") {
    Notification.requestPermission().then((r) => console.log(r));
  }
  const eventControl = (event, data) => {
    console.log("dragging");

    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
      let newPos = { x: data.x, y: data.y };
      const width = draggableRef.current.offsetWidth;
      const height = draggableRef.current.offsetHeight;
      const pageWidth = document.documentElement.scrollWidth;
      const pageHeight = document.documentElement.scrollHeight;
      // Check if the new position is outside the boundaries of the screen
      if (
        newPos.x + width > pageWidth ||
        Math.abs(newPos.y) + height > pageHeight ||
        newPos.x < 0 ||
        newPos.y < 0
      ) {
        newPos = { x: 0, y: 0 };
      }
      setDraggablePosition(newPos);
      console.log(newPos.x, newPos.y);
    }

    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };
  return (
    <div
      className={`${
        currentBackground === 0
          ? "bg-[#1a7992cc]"
          : currentBackground === 1
          ? "bg-[rgba(53,118,106,0.8)]"
          : "bg-[rgb(107,60,60,0.76)]"
      } min-h-screen w-full text-white flex flex-col justify-center transition-all ease-in-out duration-500 box-border`}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Slide}
      />
      <Header />
      <WarningDialog
        text={
          "This app now only works with 768px screen above. Please use 768px screen to experience the best features."
        }
        screen="md"
        onClose={() => {}}
      />
      <div className="text-center w-full flex-1">
        <div className="absolute z-10 right-0 overflow-hidden flex flex-col top-[40%]">
          {!(tasks.length > 0) ? (
            <>
              <span className="z-6 pl-2 text-2xl block mr-4 md:block cursor-default font-medium">
                Add your task here 👋
              </span>
              <div className="z-30 group self-end">
                <ArrowLeftCircleIcon
                  className="h-14 w-14 cursor-pointer translate-x-[40px] transform transition duration-300  group-hover:translate-x-[5px] mr-4 self-end"
                  onClick={handleOpenTaskForm}
                />
              </div>
            </>
          ) : (
            <div className="group self-end">
              <ArrowLeftCircleIcon
                className="h-14 w-14 cursor-pointer translate-x-[40px] transform transition duration-300  group-hover:translate-x-[5px] mr-4 self-end"
                onClick={handleOpenTaskForm}
              />
            </div>
          )}
          {/* <Arrow className="arrow animate-headShake" />
          <svg viewBox="0 0 250 250" className="rotate-[35deg] mr-8">
            <path
              id="curve"
              d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
            />
            <text width="250" className="animate-headShake">
              <textPath id="curveText" xlinkHref="#curve">
                Add your task here 👋
              </textPath>
            </text>
          </svg> */}
        </div>
        <div className="w-full flex flex-col m-auto px-3 items-center relative">
          <div
            className={`${
              activeTab === "home" ? "flex" : "hidden"
            } w-full flex flex-col`}
            aria-hidden={activeTab !== "home"}
          >
            <Pomodoro
              tasks={tasks}
              openTaskForm={taskFormRef}
              handlerMusicComponent={handleOpenMusicComponent}
            />
          </div>
          <div
            className={`
              ${activeTab === "groupVideoCall" ? "md:flex hidden" : "hidden"}
              z-10 bg-white rounded-lg mt-4 shadow-lg overflow-hidden relative min-w-3/4 max-w-[1600px] w-[95%] h-[85%] justify-center flex min-h-[780px] max-h-[780px]`}
            aria-hidden={activeTab !== "groupVideoCall"}
          >
            <VideoCallPanel activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div
            className={`${
              activeTab === "chat" ? "md:flex hidden" : "hidden"
            } bg-white z-20 rounded-lg mt-4 shadow-lg overflow-hidden relative min-w-3/4 max-w-[1200px] w-3/4 h-[700px] max-h-[700px] justify-center min-h-[500px]`}
            // style={{
            //   display: activeTab === "chat" ? "flex" : "none",
            // }}
            aria-hidden={activeTab !== "chat"}
          >
            <Chat activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div
            className={`${
              activeTab === "chatPDF" ? "md:flex hidden" : "hidden"
            } bg-white z-20 rounded-lg mt-4 shadow-lg relative justify-center min-w-[90%] w-[95%] min-h-[750px] h-[800px]`}
            // style={{
            //   display: activeTab === "chat" ? "flex" : "none",
            // }}
            aria-hidden={activeTab !== "chatPDF"}
          >
            <ChatPDF activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          {settingInfo.showSetting && (
            // container: {
            //   backgroundColor: "rgba(0,0,0,0.4)",
            //   position: "fixed",
            //   top: 0,
            //   left: 0,
            //   width: "100%",
            //   height: "100%",
            //   zIndex: 999,
            //   display: "flex",
            //   pointerEvents: "auto",
            //   justifyContent: "center",
            //   // overflow: "auto",
            //   alignItems: "center",
            //   transition: "all 0.2s ease-in 0s",
            //   padding: "48px 0px",
            //   boxSizing: "border-box",
            // },
            <div className="overflow-auto bg-[rgba(0,0,0,0.4)] fixed top-0 left-0 w-full h-full z-[999] md:flex hidden justify-center items-center transition-all ease-in py-12 box-border">
              <div className="md:flex hidden min-h-[600px] text-black m-auto z-[100] transform transition-all ease-in bg-white rounded-xl overflow-hidden relative h-[85%] min-w-[600px] w-1/2 max-w-1/2 justify-center min-h-[600[px]">
                {settingInfo.showSetting && <SettingPanel />}
              </div>
            </div>
          )}
          {settingInfo.showReport && (
            <>
              <div className="overflow-auto bg-[rgba(0,0,0,0.4)] fixed top-0 left-0 w-full h-full z-[999] xl:flex hidden justify-center items-center transition-all ease-in py-12 box-border">
                <div className="max-w-full w-[90%] min-w-[1280px] h-full text-black m-auto z-[100] transform transition-all ease-in bg-white rounded-xl overflow-hidden relative max-w-1/2 justify-center">
                  {isLogin ? (
                    <>
                      {isLoading && (
                        <div className="w-full h-full flex items-center justify-center">
                          <svg
                            aria-hidden="true"
                            className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-sky-400"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      )}
                      {!isLoading && <Report userSessions={userSessions} />}
                    </>
                  ) : (
                    <Report userSessions={defaultDatePicker()} />
                  )}
                </div>
              </div>
              {window.innerWidth < 1280 && (
                <WarningDialog
                  text={
                    "The Analytic Dashboard feature now only works with 1280px screen. Please use 1280px screen to experience the best features."
                  }
                  screen="xl"
                  onClose={() => settingInfo.setShowReport(false)}
                  // we allow user to close this warning since they can still use other features in the app
                />
              )}
            </>
          )}

          {/* <TodoList tasks={tasks} setTasks={setTasks} /> */}

          <TaskFormSlide ref={taskFormRef} tasks={tasks} setTasks={setTasks} />
          <Music
            openMusicComponent={settingInfo.openMusicComponent}
            setOpenMusicComponent={settingInfo.setOpenMusicComponent}
          />
        </div>
      </div>
      {/*<Draggable handle=".draggable-handle-tool-bar">*/}
      <Toolbar
        openHome={showHome}
        openGroupVideoCall={showGroupVideoCall}
        openChat={showChat}
        openChatPDF={showChatPDF}
      />
      {/*</Draggable>*/}
      {settingInfo.openPopUpYoutube && (
        <div className="absolute h-screen w-screen overflow-hidden pointer-events-none">
          <Draggable
            handle=".draggable-handle-ytb-popup"
            onDrag={eventControl}
            onStop={eventControl}
            position={{ x: draggablePosition.x, y: draggablePosition.y }}
            defaultPosition={{ x: draggablePosition.x, y: draggablePosition.y }}
          >
            <Resizable
              defaultSize={{
                height: 410,
                width: 450,
              }}
              style={{
                display: settingInfo.openPopUpYoutube ? "block" : "none",
                zIndex: settingInfo.openPopUpYoutube ? 3 : -1,
                pointerEvents: "all",
              }}
              minHeight={400}
              minWidth={400}
              maxWidth={1200}
              maxHeight={900}
            >
              <div
                ref={draggableRef}
                className={
                  settingInfo.openPopUpYoutube
                    ? "visible cursor-move rounded-lg draggable-handle-ytb-popup flex flex-col h-full w-full max-h-[900px] max-w-[1200px]"
                    : "invisible cursor-move rounded-lg draggable-handle-ytb-popup flex-col h-full w-full max-h-[900px] max-w-[1200px]"
                }
              >
                <div className="h-[28px] rounded-t-lg w-full top-0 bg-[rgba(18,18,18,0.55)] backdrop-blur-[30px] flex items-center p-1.5">
                  <Minimize2
                    className="w-4 h-4 ml-auto hover:text-sky-400 cursor-pointer"
                    onClick={handleMinimizePopUpYoutube}
                  />
                </div>
                <YoutubeVideo video={settingInfo.currentYoutube} />
              </div>
            </Resizable>
          </Draggable>
          {!settingInfo.openMusicComponent &&
            !settingInfo.openPopUpYoutube &&
            settingInfo.playNow && (
              <Draggable
                handle=".draggable-handle-ytb-icon"
                onDrag={eventControl}
                onStop={eventControl}
                position={{ x: draggablePosition.x, y: draggablePosition.y }}
                defaultPosition={{
                  x: draggablePosition.x,
                  y: draggablePosition.y,
                }}
              >
                <div
                  onClick={handleOpenPopUpYoutubeVideo}
                  className="cursor-pointer draggable-handle-ytb-icon w-fit z-10 absolute flex pointer-events-auto"
                >
                  <YoutubeButton
                    thumbnail={
                      settingInfo.currentChannel?.snippet.thumbnails
                        ? settingInfo.currentChannel?.snippet.thumbnails.default
                            .url
                        : settingInfo.youtubeImgDefault
                    }
                    playNow={settingInfo.playNow}
                  />
                </div>
              </Draggable>
            )}
        </div>
      )}
    </div>
  );
};

export default HomePage;
