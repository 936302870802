import React, { memo, useContext, useMemo, useState } from "react";
import Task from "./Task";
import { days, months } from "../../common/global";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useUserCategory } from "../../hooks/useUserCategory";

const convertDateTime = (data) => {
  let date = new Date(data[0].date);
  let dayOfWeek = days[date.getDay() + 1];
  let day = date.getDate();
  let month = months[date.getMonth() + 1];
  let year = date.getFullYear();
  return dayOfWeek + ", " + day + " " + month + " " + year;
};

const handleMergeDataWithCategory = (copiedData, userCategory) => {
  if (!userCategory) return [];
  return copiedData.map((session) => {
    let categoryInfo = userCategory.find(
      (category) => category._id === session.category
    );
    if (categoryInfo) {
      session.category = { id: session.category };
      session.category.color = categoryInfo.color;
      session.category.name = categoryInfo.name;
      return session;
    }
  });
};
const DailyTask = ({ data }) => {
  const { userCategories } = useUserCategory();
  const [showTask, setShowTask] = useState(true);
  let copiedData = JSON.parse(JSON.stringify(data));
  const memoHandleConvertDateTime = useMemo(
    () => convertDateTime(data),
    [data]
  );

  const memoMergeDataWithCategory = useMemo(
    () => handleMergeDataWithCategory(copiedData, userCategories),
    [data, userCategories]
  );
  const handleShowTask = () => {
    setShowTask((prev) => !prev);
  };
  return (
    <>
      <div
        onClick={handleShowTask}
        className="text-left select-none bg-white mb-1 mt-1 pl-1 cursor-pointer hover:bg-zinc-50 h-10 rounded fill-black  font-semibold space-x-1 items-center text-sm flex"
      >
        {showTask ? (
          <ChevronDownIcon className="w-4 h-4 " />
        ) : (
          <ChevronRightIcon className="w-4 h-4 " />
        )}
        <span>{memoHandleConvertDateTime}</span>
      </div>

      {showTask && (
        <div className="flex gap-2 flex-col	rounded bg-zinc-100 p-4">
          {memoMergeDataWithCategory.map((taskData, index) => (
            <React.Fragment key={index}>
              <Task data={taskData} />
              {index < memoMergeDataWithCategory.length - 1 && (
                <div className="w-full bg-gray-200 h-0.5"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default memo(DailyTask, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
