import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { useDropzone } from "react-dropzone";
const ModalUploadFile = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState({});
  const cancelButtonRef = useRef(null);
  useImperativeHandle(
    ref,
    () => {
      return {
        open(opt) {
          setOption(opt);
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
      };
    },
    []
  );

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#d4d4d8",
    borderStyle: "dashed",
    backgroundColor: "white",
    color: "#a1a1aa",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "application/pdf": [] }, maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const handleSubmitFile = async (file) => {
    await option.handleUploadFile(file);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-[80%] items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <XMarkIcon
                  className={
                    "h-6 w-6 m-2 hover:text-sky-400 cursor-pointer right-0 absolute text-zinc-500"
                  }
                  aria-hidden="true"
                  onClick={() => setOpen(false)}
                />
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left cursor-default w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {option.title}
                      </Dialog.Title>

                      <div className="flex items-center space-x-2 w-full mt-2">
                        <section className="container">
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p>
                              Drag and drop file here, or click to select file
                            </p>
                            <em>(Only *.pdf will be accepted)</em>
                          </div>
                          <aside>
                            <span className="font-semibold">Files</span>
                            <ul className="italic break-all">
                              {acceptedFileItems}
                            </ul>
                          </aside>
                        </section>
                      </div>
                      {props.isUploading && (
                        <div className="flex">
                          <div className="ml-auto">
                            <svg
                              aria-hidden="true"
                              className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-sky-400"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                      {!props.isUploading && (
                        <button
                          className="bg-sky-300 cursor-pointer items-center hover:bg-sky-400 transform hover:scale-105 transition ease-in-out duration-200 py-2 px-4 rounded-lg text-white w-fit ml-auto mt-2 flex"
                          onClick={() => handleSubmitFile(acceptedFiles[0])}
                        >
                          Upload
                          <ArrowUpTrayIcon className="h-[18px] w-[18px] ml-1" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}, []);
export default ModalUploadFile;
