import { useContext } from "react";
import { SettingContext } from "../../context/SettingContext";
import { getChannelDetail } from "../../api/service";

const useChangeYoutubeVideo = () => {
  const settingInfo = useContext(SettingContext);
  const handleChangeYouTubeVideo = async (video) => {
    const channelDetail = await getChannelDetail(video.snippet.channelId);
    settingInfo.setCurrentYoutube(video);
    settingInfo.setCurrentChannel(channelDetail.items[0]);
  };
  return { handleChangeYouTubeVideo };
};

export default useChangeYoutubeVideo;
