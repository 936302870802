import React, { useContext, useEffect, memo, useState } from "react";
import Youtube from "react-youtube";
import { SettingContext } from "../../context/SettingContext";

const YoutubeVideo = ({ video }) => {
  const settingInfo = useContext(SettingContext);
  const playerRef = settingInfo.popUpYoutubeRef;
  useEffect(() => {
    if (settingInfo.isPlayPopUpYoutubeNow) {
      playerRef.current.internalPlayer.seekTo(settingInfo.currentTimeOfYoutube);
    }
  }, [
    settingInfo.currentTimeOfYoutube,
    playerRef,
    settingInfo.isPlayPopUpYoutubeNow,
  ]);

  const isReady = () => {
    settingInfo.setIsLoadingVideo(false);
  };

  const [opts, setOpts] = useState({
    height: 450,
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  });
  const handleOnPlay = () => {
    playerRef.current.internalPlayer.playVideo();
    settingInfo.setPlayNow(true);
  };

  const handleOnPause = () => {
    playerRef.current.internalPlayer.pauseVideo();
    settingInfo.setPlayNow(false);
  };

  return (
    <>
      {video && (
        <Youtube
          style={
            settingInfo.isLoadingVideo
              ? { display: "none" }
              : {
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }
          }
          ref={playerRef}
          iframeClassName="w-full h-full rounded-b-lg"
          opts={
            settingInfo.isPlayPopUpYoutubeNow
              ? {
                  height: "100%",
                  width: "100%",
                  playerVars: {
                    autoplay: 1,
                  },
                }
              : opts
          }
          videoId={video.id}
          onReady={isReady}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
        />
      )}
    </>
  );
};

export default memo(YoutubeVideo);
