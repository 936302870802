import React, { memo, useContext } from "react";
import { SettingContext } from "../../context/SettingContext";
import { XMarkIcon } from "@heroicons/react/20/solid";
import useChangeYoutubeVideo from "../hooks/useChangeYoutubeVideo";

const VideoList = ({ detail }) => {
  const settingInfo = useContext(SettingContext);
  const { handleChangeYouTubeVideo } = useChangeYoutubeVideo();
  const handlePlayNow = async () => {
    if (settingInfo.currentYoutube.id === detail.id) {
      return;
    }
    await handleChangeYouTubeVideo(detail);
    settingInfo.setIsPlayYoutubeNow(true);
    settingInfo.setPlayNow(true);
    settingInfo.youtubeRef.current.internalPlayer.playVideo();
  };
  const handleRemoveFromPlaylist = async (video) => {
    const newList = settingInfo.youtubeList.filter(
      (item) => item.id !== video.id
    );
    settingInfo.setYoutubeList(newList);
    if (settingInfo.currentYoutube.id === video.id) {
      await handleChangeYouTubeVideo(
        newList.length > 0 ? newList[0] : settingInfo.videoYoutubeDefault
      );
      settingInfo.setIsPlayYoutubeNow(newList.length === 0 ? false : true);
      settingInfo.setPlayNow(newList.length === 0 ? false : true);
    }
  };
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };
  return (
    <div className="flex relative group/icons ">
      <div className="flex gap-2 cursor-default w-full relative group-hover/icons:opacity-70">
        <img
          alt={decodeHtmlEntities(detail.snippet.title)}
          onClick={handlePlayNow}
          className={
            settingInfo.currentYoutube.id === detail.id
              ? "cursor-default rounded-xl"
              : " cursor-pointer rounded-xl "
          }
          src={detail.snippet.thumbnails.default.url}
        />

        <div className="flex flex-col text-start min-w-[300px] max-w-[300px] py-2">
          <h3
            onClick={handlePlayNow}
            className={
              settingInfo.currentYoutube.id === detail.id
                ? "cursor-default titleYoutube font-semibold "
                : "font-semibold titleYoutube cursor-pointer "
            }
          >
            {decodeHtmlEntities(detail.snippet.title)}
          </h3>
          <span className="text-sm text-gray-700 font-normal">
            {detail.snippet.channelTitle}
          </span>{" "}
          {detail.snippet.liveBroadcastContent === "live" && (
            <p className="px-2 text-sm mt-auto font-semibold text-white bg-red-400 rounded-2xl w-fit">
              Live
            </p>
          )}
        </div>
        <div className="flex group/tooltip1 h-fit ml-auto pr-2">
          <span className="group-hover/tooltip1:block top-[35%] z-10 left-[65%] hidden absolute text-white font-semibold px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
            Remove from this playlist
          </span>
          <XMarkIcon
            onClick={() => handleRemoveFromPlaylist(detail)}
            className="group-hover/icons:block hidden relative cursor-pointer opacity:60 hover:opacity-100 text-center w-6 h-6 mt-2 fill-sky-400"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(VideoList, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
