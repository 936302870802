import { memo } from "react";
import Chart from "react-apexcharts";

const DailyChart = ({ data, categoriesAxis }) => {
  const options = {
    chart: {
      type: "bar",
      height: 200,
      width: 450,
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Daily Statistic",
      align: "left",
      margin: 10,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: true,
    },

    noData: {
      text: "No sessions to show!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#71717a",
        fontSize: "14px",
        fontWeight: 600,
      },
    },
    xaxis: {
      categories: categoriesAxis,
      labels: {
        formatter: function (val) {
          if (val % 2 !== 0) {
            return val;
          } else return "";
        },
        style: {
          fontSize: "10px",
          colors: "#6b7280",
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        dataLabels: {
          position: "top",
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "10px",
              fontWeight: 600,
            },
          },
        },
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },
    // dataLabels: {
    //     enabled:true,
    //     formatter: function (val) {
    //         return val + "%";
    //     },
    //     style: {
    //         fontSize: '8px',
    //         colors: ["#304758"]
    //     },
    // },
    dataLabels: {
      enabled: false,
      formatter: function (_val, opt) {
        let series = opt.w.config.series;
        let idx = opt.dataPointIndex;
        const total = series.reduce((total, self) => {
          let val = self.data[idx] ? self.data[idx] : 0;
          return total + val / 60;
        }, 0);
        if (total === 0) {
          return "";
        }
        return Math.round(total * 100) / 100 + "h";
      },
      style: {
        colors: ["#000"],
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return value + "m";
        },
      },
    },
  };
  return (
    <div id="dailyChart" className={"bg-gray-400/20 rounded p-2 m-2  "}>
      <Chart
        options={options}
        series={data}
        type="bar"
        height={280}
        minWidth={450}
        maxWidth={500}
      />
    </div>
  );
};

export default memo(DailyChart, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
