import React, { useContext } from "react";
import {
  ChatBubbleLeftRightIcon,
  DocumentIcon,
  HomeIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { SettingContext } from "../../context/SettingContext";

export const Toolbar = ({
  openHome,
  openGroupVideoCall,
  openChat,
  openChatPDF,
}) => {
  const settingInfo = useContext(SettingContext);

  return (
    <div className="draggable-handle-tool-bar h-fit sticky bottom-0 mx-auto z-[10] flex flex-row w-fit bg-slate-200 rounded-2xl p-2 space-x-2 mb-2">
      <div className="flex group/tooltip-home h-fit pr-2 relative">
        <div onClick={openHome}>
          <span className="group-hover/tooltip-home:block top-[-125%] left-[-80%] z-10  w-max hidden absolute text-white font-semibold px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
            stayinpomo
          </span>
          <HomeIcon className="relative cursor-pointer opacity-70 hover:opacity-100 text-center w-7 h-7 fill-sky-400 " />
        </div>
      </div>
      <div className="flex group/tooltip-video-call h-fit pr-2 relative">
        <div onClick={openGroupVideoCall}>
          <span className="group-hover/tooltip-video-call:block top-[-125%] left-[-80%] z-10  w-max hidden absolute text-white font-semibold px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
            Group video call
          </span>
          <VideoCameraIcon className="relative cursor-pointer opacity-70 hover:opacity-100 text-center w-7 h-7 fill-sky-400 " />
        </div>
      </div>
      <div className="flex group/tooltip-chat-ai h-fit pr-2 relative">
        <div onClick={openChat}>
          <span className="group-hover/tooltip-chat-ai:block top-[-125%] left-[-80%] z-10 w-max hidden absolute text-white font-semibold px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
            Chat with AI
          </span>
          <ChatBubbleLeftRightIcon className="relative cursor-pointer opacity-70 hover:opacity-100 text-center w-7 h-7 fill-sky-400" />
        </div>
      </div>
      <div className="flex group/tooltip-pdf-reader h-fit pr-2 relative">
        <div onClick={openChatPDF}>
          <span className="group-hover/tooltip-pdf-reader:block top-[-125%] left-[-80%] z-10 w-max hidden absolute text-white font-semibold px-2 py-1 bg-black rounded-lg shadow-sm text-xs">
            AI PDF Reader
          </span>
          <DocumentIcon className="relative cursor-pointer  opacity-70 hover:opacity-100 text-center w-7 h-7 fill-sky-400" />
        </div>
      </div>
    </div>
  );
};
