import React, { useContext, useEffect, useRef } from "react";
import { SettingContext } from "../../../context/SettingContext";
import { Tab } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { PomodoroSetting } from "./PomodoroSetting";
import AccountSetting from "./AccountSetting";
import SubscriptionPanel from "./SubscriptionPanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = {
  Pomodoro: [
    {
      tab: 1,
    },
  ],
  Account: [
    {
      tab: 2,
    },
  ],
  Subscription: [
    {
      tab: 3,
    },
  ],
};
const SettingPanel = ({ showSetting }) => {
  const settingInfo = useContext(SettingContext);
  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        settingInfo.setShowSetting(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingInfo]);

  return (
    <>
      <div
        ref={panelRef}
        className="flex min-h-full relative min-w-[600px] flex-1 "
      >
        <Tab.Group>
          <Tab.List className="block space-y-2 rounded-xl   bg-gray-400/20 p-2 basis grow-0 shrink-0 basis-40">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full h-10 rounded-lg py-2 font-medium text-base outline-none focus:ring-2 focus:ring-sky-400",
                  selected
                    ? "text-white bg-sky-400"
                    : " hover:bg-sky-300 hover:text-white text-black"
                )
              }
            >
              Settings
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full h-10 rounded-lg py-2 font-medium text-base outline-none focus:ring-2 focus:ring-sky-400",
                  selected
                    ? "text-white bg-sky-400"
                    : " hover:bg-sky-300 hover:text-white text-black"
                )
              }
            >
              Account
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full h-10 rounded-lg py-2 font-medium text-base outline-none focus:ring-2 focus:ring-sky-400",
                  selected
                    ? "text-white bg-sky-400"
                    : " hover:bg-sky-300 hover:text-white text-black"
                )
              }
            >
              Subscription
            </Tab>
          </Tab.List>
          <Tab.Panels className="m-2 flex-1 flex overflow-auto ">
            <XMarkIcon
              className={
                "h-6 w-6  hover:text-sky-400 cursor-pointer absolute right-0 mr-2 mt-1"
              }
              aria-hidden="true"
              onClick={() => settingInfo.setShowSetting(false)}
            />
            <Tab.Panel className="w-full flex flex-col outline-none focus:ring-2 focus:ring-sky-400">
              <PomodoroSetting />
            </Tab.Panel>
            <Tab.Panel className="w-full flex flex-col outline-none focus:ring-2 focus:ring-sky-400">
              <AccountSetting />
            </Tab.Panel>
            <Tab.Panel className="w-full flex flex-col outline-none focus:ring-2 focus:ring-sky-400">
              <SubscriptionPanel />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default SettingPanel;
