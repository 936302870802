import { memo } from "react";
import Chart from "react-apexcharts";
const LineChart = ({ data, categoriesAxis }) => {
  const options = {
    chart: {
      type: "line",
      height: 200,
      width: 450,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Daily line chart",
      align: "left",
      margin: 10,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    grid: {
      show: true,
    },
    legend: {
      show: true,
    },
    noData: {
      text: "No sessions to show!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#71717a",
        fontSize: "14px",
        fontWeight: 600,
      },
    },
    xaxis: {
      categories: categoriesAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
    stroke: {
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value) + "m";
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          if (value === 0) {
            return;
          }
          return value + "m";
        },
      },
    },
  };

  return (
    <div id="lineChart" className={"bg-gray-400/20 rounded p-2 m-2  "}>
      <Chart
        options={options}
        series={data}
        type="line"
        height={280}
        minWidth={450}
        maxWidth={500}
      />
    </div>
  );
};

export default memo(LineChart, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
