import CountUp from "react-countup";
const StatisticNumber = ({
  diffStats,
  totalPomos,
  totalHours,
  totalMinutes,
  totalBreakHours,
  totalBreakMinutes,
  hourAvgDay,
  minuteAvgDay,
  bestWorkTime,
  bestWorkday,
}) => {
  return (
    <div
      className={"grid grid-rows-2 grid-cols-3 p-4 bg-gray-400/20 m-2 rounded"}
    >
      <div className={"grid self-end origin-top-left"}>
        {diffStats?.statsDifference?.totalPomoTimeRatio >= 0 ? (
          <CountUp
            start={0}
            end={diffStats.statsDifference.totalPomoTimeRatio}
            delay={0}
            suffix="%"
            prefix="+"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(5,122,83,.12)] w-fit mx-auto px-[6px] rounded text-green-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        ) : (
          <CountUp
            start={0}
            end={Math.abs(diffStats?.statsDifference?.totalPomoTimeRatio)}
            delay={0}
            suffix="%"
            prefix="-"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(209,4,18,.1215686275)] mx-auto w-fit rounded px-[6px] text-red-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        )}
        <div className="">
          <CountUp
            start={0}
            end={totalHours}
            delay={0}
            suffix="h"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
          <CountUp
            start={0}
            end={totalMinutes}
            delay={0}
            suffix="m"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
        </div>
        <span className="text-sm font-medium text-gray-600">Total hours</span>
      </div>
      <div className={"grid self-end"}>
        {diffStats?.statsDifference?.totalPomosRatio >= 0 ? (
          <CountUp
            start={0}
            end={diffStats.statsDifference.totalPomosRatio}
            delay={0}
            prefix="+"
            suffix="%"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(5,122,83,.12)] w-fit mx-auto px-[6px] rounded text-green-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        ) : (
          <CountUp
            start={0}
            end={Math.abs(diffStats?.statsDifference?.totalPomosRatio)}
            delay={0}
            suffix="%"
            prefix="-"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(209,4,18,.1215686275)] mx-auto w-fit rounded px-[6px] text-red-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        )}
        <CountUp start={0} end={totalPomos} delay={0} duration={1.25}>
          {({ countUpRef }) => (
            <span className={"text-base font-semibold"} ref={countUpRef} />
          )}
        </CountUp>
        <span className="text-sm font-medium text-gray-600">Total pomos</span>
      </div>
      <div className={"grid self-end"}>
        {diffStats?.statsDifference?.totalPomosRatio >= 0 ? (
          <CountUp
            start={0}
            end={diffStats?.statsDifference?.totalPomosRatio}
            delay={0}
            suffix="%"
            prefix="+"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(5,122,83,.12)] w-fit mx-auto px-[6px] rounded text-green-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        ) : (
          <CountUp
            start={0}
            end={Math.abs(diffStats?.statsDifference?.totalPomosRatio)}
            delay={0}
            suffix="%"
            prefix="-"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(209,4,18,.1215686275)] mx-auto w-fit rounded px-[6px] text-red-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        )}

        <div className="">
          <CountUp
            start={0}
            end={hourAvgDay}
            delay={0}
            suffix="h"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
          <CountUp
            start={0}
            end={minuteAvgDay}
            delay={0}
            suffix="m"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
        </div>
        <span className="text-sm font-medium text-gray-600">Avg/Day</span>
      </div>
      <div className={"grid self-end"}>
        {diffStats?.statsDifference?.totalBreakTimeRatio >= 0 ? (
          <CountUp
            start={0}
            end={diffStats?.statsDifference?.totalBreakTimeRatio}
            delay={0}
            suffix="%"
            prefix="+"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(5,122,83,.12)] w-fit mx-auto px-[6px] rounded text-green-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        ) : (
          <CountUp
            start={0}
            end={Math.abs(diffStats?.statsDifference?.totalBreakTimeRatio)}
            delay={0}
            suffix="%"
            prefix="-"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <div
                className={
                  "flex justify-center text-xs font-semibold bg-[rgba(209,4,18,.1215686275)] mx-auto w-fit rounded px-[6px] text-red-500"
                }
              >
                <span ref={countUpRef}>%</span>
              </div>
            )}
          </CountUp>
        )}

        <div className="">
          <CountUp
            start={0}
            end={totalBreakHours}
            delay={0}
            suffix="h"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
          <CountUp
            start={0}
            end={totalBreakMinutes}
            delay={0}
            suffix="m"
            duration={1.25}
          >
            {({ countUpRef }) => (
              <span className={"text-base font-semibold"} ref={countUpRef} />
            )}
          </CountUp>
        </div>
        <span className={"text-sm font-medium text-gray-600"}>Total Break</span>
      </div>
      <div className={"grid self-end"}>
        <span className={"text-base font-semibold"}>{bestWorkday}</span>
        <span className={"text-sm font-medium text-gray-600"}>
          Best Workday
        </span>
      </div>
      <div className={"grid self-end"}>
        <span className={"text-base font-semibold"}>{bestWorkTime}</span>
        <span className={"text-sm font-medium text-gray-600"}>
          Best Work Time
        </span>
      </div>
    </div>
  );
};
export default StatisticNumber;
