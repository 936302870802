import React, { memo } from "react";
const parseDateToHours = (date) => {
  date = new Date(date);
  return date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
};
const parseDateToMinutes = (date) => {
  date = new Date(date);
  return date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
};
const Task = ({ data }) => {
  const startDate = new Date(data.sessionStartAt);
  const endDate = new Date(data.sessionDoneAt);

  const startHours = parseDateToHours(startDate);
  const endHours = parseDateToHours(endDate);
  const startMin = parseDateToMinutes(startDate);
  const endMin = parseDateToMinutes(endDate);
  const totalHour = Math.floor(data.sessionTime / 60);
  const totalMin = data.sessionTime % 60;

  return (
    <div className="flex h-full justify-center gap-2 min-h-[7rem]">
      <div className="text-sm min-w-[70px] flex justify-center items-center">
        <div className="text-center h-full min-h-full flex flex-col items-center">
          <span className="font-semibold text-zinc-600">
            {startHours}:{startMin}
          </span>
          <div
            className="w-0.5 flex-1"
            style={{ background: `${data.category.color}` }}
          ></div>
          {data.disruptedList.length > 0 && (
            <span className="text-zinc-400 text-xs font-semibold">
              Disrupted {data?.disruptedList.length} times
            </span>
          )}
          <div
            className="w-0.5 flex-1"
            style={{ background: `${data.category.color}` }}
          ></div>
          <span className="font-semibold text-zinc-600">
            {endHours}:{endMin}
          </span>
        </div>
      </div>

      <div className="text-sm w-full flex flex-col">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-sm text-zinc-700">
            {data.category.name === "Break" && data.sessionTitle === ""
              ? "Break session"
              : data.sessionTitle}
          </span>
        </div>

        <div className="flex text-left overflow-ellipsis break-all text-zinc-500">
          <span>{data.sessionDescription}</span>
        </div>
        <div className="text-black flex-1 justify-end flex flex-col">
          <div className="flex">
            <span className="font-medium">Total time: &nbsp;</span>
            {totalHour >= 1 && (
              <span className="font-semibold">
                {totalHour}H{totalMin}M
              </span>
            )}
            {totalHour < 1 && (
              <span className="font-semibold "> {totalMin}M</span>
            )}
          </div>
        </div>
      </div>
      <div className="">
        <p
          style={{ background: `${data.category.color}` }}
          className={
            "text-white whitespace-nowrap text-xs font-medium inline-flex py-1 px-2 rounded"
          }
        >
          {data.category.name}
        </p>
      </div>
    </div>
  );
};

export default memo(Task, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
