import { AES, enc } from "crypto-js";

const encryptAndStoreData = (key, data) => {
  const encryptedData = AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPT_KEY
  ).toString();
  localStorage.setItem(key, encryptedData);
};

const retrieveAndDecryptData = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const decryptedBytes = AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPT_KEY
    );
    const decryptedData = JSON.parse(decryptedBytes.toString(enc.Utf8));
    return decryptedData;
  }
  return null;
};

export { encryptAndStoreData, retrieveAndDecryptData };
