import React, {
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import {
  ClipboardDocumentIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import TaskList from "./index";
import { SettingContext } from "../../context/SettingContext";
import { AuthContext } from "../../context/AuthContext";
import PopUp from "../common/PopUp";

const TaskFormSlide = forwardRef((props, ref) => {
  const settingInfo = useContext(SettingContext);
  const [finishAt, setFinishAt] = useState(null);
  const [totalPomo, setTotalPomo] = useState(null);
  const [act, setAct] = useState(null);
  const { isLogin } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => {
      return {
        open(opt) {
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
      };
    },
    []
  );

  const options = [
    {
      name: "Clear finished tasks",
      handle: () => {
        const newList = [...props.tasks].filter((task) => task.done !== true);
        props.setTasks(newList);
      },
      icon: TrashIcon,
    },
    {
      name: "Save as template",
      handle: () => {
        if (isLogin) {
          if (props.tasks.length < 1) {
            window.alert("Add task first");
          } else {
            settingInfo.setShowSaveTemplate(true);
          }
        } else {
          window.alert("You must login first to use this feature.");
        }
      },
      icon: ClipboardDocumentIcon,
    },
    {
      name: "Add tasks from template",
      handle: () => {
        if (isLogin) {
          settingInfo.setShowAddTemplate(true);
        } else {
          window.alert("You must login first to use this feature.");
        }
      },
      icon: PlusIcon,
    },
    {
      name: "Clear all tasks",
      handle: () => {
        props.setTasks([]);
      },

      icon: TrashIcon,
    },
  ];
  return (
    <div
      className={
        open
          ? "w-full h-full fixed inset-0 z-30 text-black hidden md:block"
          : "w-full h-full fixed inset-0 invisible z-30 text-black hidden md:block"
      }
    >
      <div
        onClick={() => {
          setOpen(false);
        }}
        className={
          open
            ? "w-full h-full z-30 duration-500 ease-out transition-all inset-0 absolute bg-gray-700 opacity-50"
            : "w-full h-full z-30 duration-500 ease-out transition-all inset-0 absolute bg-gray-700 " +
              " opacity-0"
        }
      ></div>
      <div
        id="slideover"
        className={
          open
            ? " w-screen max-w-2xl z-30 bg-white h-full absolute right-0 duration-500 ease-in-out translate-x-0 transition-all"
            : " w-screen max-w-2xl z-30 bg-white h-full absolute right-0 duration-500 ease-in-out transition-all translate-x-full"
        }
      >
        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
          <div className="px-4 sm:px-6 flex justify-between content-center">
            <div className="text-2xl font-semibold text-sky-400 cursor-default">
              Task list
            </div>
            <PopUp options={options} />
          </div>
          <div className="relative mt-6 flex-1 px-4 sm:px-6">
            <TaskList tasks={props.tasks} setTasks={props.setTasks} />
          </div>
        </div>
      </div>
    </div>
  );
}, []);
export default memo(TaskFormSlide, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
//
//
