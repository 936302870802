import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import Youtube from "react-youtube";
import { SettingContext } from "../../context/SettingContext";
import useChangeYoutubeVideo from "../hooks/useChangeYoutubeVideo";

const VideoDetail = ({ video, channel }) => {
  const settingInfo = useContext(SettingContext);
  const playerRef = settingInfo.youtubeRef;
  const { handleChangeYouTubeVideo } = useChangeYoutubeVideo();
  const [opts, setOpts] = useState({
    height: 350,
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  });
  useEffect(() => {
    if (settingInfo.isPlayYoutubeNow && settingInfo.currentTimeOfYoutube) {
      playerRef.current.internalPlayer.seekTo(settingInfo.currentTimeOfYoutube);
      playerRef.current.internalPlayer.playVideo();
    }
    if (settingInfo.isPlayYoutubeNow) {
      playerRef.current.internalPlayer.playVideo();
    }
  }, [
    settingInfo.currentTimeOfYoutube,
    playerRef,
    settingInfo.isPlayYoutubeNow,
  ]);

  // useEffect(() => {
  //   if (settingInfo.youtubeList == 0) {
  //     settingInfo.setPlayNow(false);
  //     setOpts({
  //       height: 350,
  //       width: "100%",
  //       playerVars: {
  //         autoplay: 0,
  //       },
  //     });
  //   }
  // }, [settingInfo.youtubeList]);
  const isReady = () => {
    settingInfo.setIsLoadingVideo(false);
  };

  const handleGoToChannel = () => {
    window.open(
      `https://youtube.com/channel/${channel?.id}`,
      "_blank",
      "noopener, noreferrer"
    );
  };

  const handleOnPlay = () => {
    playerRef.current.internalPlayer.playVideo();
    settingInfo.setPlayNow(true);
    // settingInfo.setIsPlayYoutubeNow(true);
  };

  const handleOnPause = () => {
    playerRef.current.internalPlayer.pauseVideo();
    // settingInfo.setIsPlayYoutubeNow(false);
    settingInfo.setPlayNow(false);
  };
  const handleOnEndVideo = async () => {
    const videoYoutubeIdx = settingInfo.youtubeList.findIndex(
      (item) => item.id === settingInfo.currentYoutube.id
    );
    if (videoYoutubeIdx === settingInfo.youtubeList.length - 1) {
      settingInfo.setPlayNow(false);
      return;
    }
    if (settingInfo.youtubeList.length > 1) {
      await handleChangeYouTubeVideo(
        settingInfo.youtubeList[videoYoutubeIdx + 1]
      );
    }
  };
  const formatSubscribers = () => {
    const subscribers = channel?.statistics.subscriberCount;
    const removeTrailingZero = (number) => {
      return number.replace(/\.?0+$/, "");
    };

    if (subscribers >= 1000000000) {
      const billions = Math.round(subscribers / 10000000) / 100;
      if (billions >= 1000) {
        return "1T";
      }
      if (billions.toFixed(2) === "10.00") {
        return "9.99B";
      }
      return `${removeTrailingZero(billions.toFixed(2))}B`;
    } else if (subscribers >= 1000000) {
      const millions = Math.round(subscribers / 10000) / 100;
      if (millions >= 1000) {
        return "1M";
      }
      return `${removeTrailingZero(millions.toFixed(2))}M`;
    } else if (subscribers >= 1000) {
      const thousands = Math.round(subscribers / 10) / 100;
      if (thousands >= 1000) {
        return "1K";
      }
      return `${removeTrailingZero(thousands.toFixed(2))}K`;
    } else {
      return subscribers?.toString();
    }
  };
  const memoFormatSubscribers = useMemo(
    () => formatSubscribers(),
    [channel?.statistics]
  );
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };
  return (
    <div className="mt-4 w-full h-full">
      <div className="">
        {settingInfo.isLoadingVideo && (
          <>
            <div
              role="status"
              className="flex items-center justify-center h-72 w-full bg-gray-300 rounded-lg animate-pulse "
            >
              <svg
                className="w-12 h-12 text-gray-200 "
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 384 512"
              >
                <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z" />
              </svg>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-2 animate-pulse bg-white justify-between items-center">
              <div className="h-2 bg-gray-200 rounded col-span-2"></div>
              <div className="rounded-full bg-gray-200 h-10 w-10 col-span-1 justify-self-end	"></div>
            </div>
          </>
        )}

        {video && (
          <Youtube
            style={
              settingInfo.isLoadingVideo
                ? { display: "none" }
                : { display: "block" }
            }
            iframeClassName="rounded-lg"
            opts={
              settingInfo.isPlayYoutubeNow
                ? {
                    height: 350,
                    width: "100%",
                    playerVars: {
                      autoplay: 1,
                    },
                  }
                : opts
            }
            videoId={video.id}
            onReady={isReady}
            onPlay={handleOnPlay}
            onPause={handleOnPause}
            onEnd={handleOnEndVideo}
            ref={playerRef}
          />
        )}
      </div>
      <div
        className={
          settingInfo.isLoadingVideo
            ? "hidden"
            : "mt-2 text-start flex flex-col items-start py-2 justify-between"
        }
      >
        <p className="font-semibold titleYoutube text-base flex cursor-default">
          {video ? decodeHtmlEntities(video.snippet.title) : "--"}
        </p>
        <div className="flex items-center space-x-2 mt-2">
          <img
            className={
              settingInfo.playNow
                ? "animate-spin-slow rounded-full h-12 w-12 cursor-pointer"
                : "rounded-full h-12 w-12 cursor-pointer"
            }
            onClick={handleGoToChannel}
            alt="channel"
            src={
              channel?.snippet.thumbnails
                ? channel?.snippet.thumbnails.default.url
                : settingInfo.youtubeImgDefault
            }
          />
          <div>
            <p
              className="flex text-sm font-semibold cursor-pointer"
              onClick={handleGoToChannel}
            >
              {channel ? channel.snippet.title : "--"}
            </p>
            <p className="text-xs text-gray-600 float-left font-medium cursor-default">
              {channel ? memoFormatSubscribers : "--"} subscribers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VideoDetail, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
