import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeRender = ({ response, indexMsg }) => {
  // Define the delimiters
  const codeDelimiter = "```"; // Triple backticks used in markdown to denote code blocks
  // Split the response by the code delimiter
  const sections = response.split(codeDelimiter);
  // Initialize an array to hold code blocks with their languages
  const codeBlocks = sections
    .filter((_, index) => index % 2 === 1) // Code is inside the backticks
    .map((section) => {
      if (section === "") return;
      // Split the section by newline to separate the language name from the code
      const [firstLine, ...codeLines] = section.split("\n");
      // The first line is the language name, the rest is the code
      const language = firstLine.trim();
      const code = codeLines.join("\n");
      return { language, code };
    });

  // Highlight code references in the text
  // const highlightCodeReferences = (text) => {
  //   // Regular expression to find code references (e.g., `Test.jsx`)
  //   const regex = /`([^`]+)`/g;
  //   return text.split(regex).map((part, index) => {
  //     // Check if the part is a code reference
  //     if (index % 2 === 1) {
  //       // Return the code reference with a special styling
  //       return (
  //         <span
  //           key={index}
  //           className="bg-zinc-500 text-white rounded-md px-1 py-0.5 font-mono text-sm"
  //         >
  //           {part}
  //         </span>
  //       );
  //     }
  //     // Return normal text
  //     return part;
  //   });
  // };
  const formatAndHighlightText = (text) => {
    // Split text into lines for processing headings
    const lines = text.split("\n");

    return lines.map((line, index) => {
      // Check for headings
      if (line.startsWith("#")) {
        const headingText = line.replace(/^#+\s*/, "");
        return (
          <div
            key={`heading-${index}`}
            className="text-lg font-semibold mb-0.5"
          >
            {processBoldAndCode(headingText)}
          </div>
        );
      } else {
        return (
          <div className="" key={`text-${index}`}>
            {processBoldAndCode(line)}
          </div>
        );
      }
    });
  };

  // Helper function to process bold text and code references within a line
  const processBoldAndCode = (line) => {
    // Regex to find bold and code references
    const regex = /(\*\*([^*]+)\*\*)|(`([^`]+)`)/g;
    let match;
    let lastIndex = 0;
    const parts = [];

    while ((match = regex.exec(line)) !== null) {
      // Add text before match
      if (match.index > lastIndex) {
        parts.push(line.substring(lastIndex, match.index));
      }
      if (match[1]) {
        // Bold text
        parts.push(
          <span className="font-semibold my-2" key={`bold-${match.index}`}>
            {match[2]}
          </span>
        );
      } else if (match[3]) {
        // Code reference
        parts.push(
          <span
            className="bg-zinc-700 text-white rounded-md px-1 py-0.5 font-mono text-sm"
            key={`code-${match.index}`}
          >
            {match[4]}
          </span>
        );
      }

      lastIndex = match.index + match[0].length;
    }
    // Add remaining text
    if (lastIndex < line.length) {
      parts.push(line.substring(lastIndex));
    }
    return parts;
  };

  // Filter out and join text sections
  const text = sections.filter((_, index) => index % 2 === 0).join("");
  const copyToClipboard = (code, index) => {
    if ("clipboard" in navigator) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          document.getElementsByClassName(`copy-button-${index}`)[0].innerText =
            "Copied!";
          setTimeout(() => {
            document.getElementsByClassName(
              `copy-button-${index}`
            )[0].innerText = "Copy code";
          }, 5000);
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    } else {
      console.error("Clipboard API not available.");
    }
  };
  return (
    <div className="bg-zinc-500 text-start overflow-x-scroll text-zinc-100 rounded-lg py-2 px-3">
      <div className="space-y-1">{formatAndHighlightText(text)}</div>
      {codeBlocks.map(({ language, code }, index) => (
        <div className="relative group overflow-auto">
          <button
            onClick={() => copyToClipboard(code, indexMsg)}
            className={`copy-button-${indexMsg} group-hover:opacity-100 absolute opacity-0 right-0 mt-2.5 mr-1 text-sm bg-zinc-600 font-semibold rounded-lg px-2 py-1`}
          >
            Copy code
          </button>
          <SyntaxHighlighter
            className="rounded-lg"
            key={index}
            customStyle={{
              fontSize: "14px",
              fontWeight: "500",
            }}
            language={language}
            style={prism}
          >
            {code}
          </SyntaxHighlighter>
        </div>
      ))}
    </div>
  );
};

export default CodeRender;
