import React, { memo, useContext } from "react";
import VideoList from "./VideoList";
import { SettingContext } from "../../context/SettingContext";

const VideoPlaylist = ({ youtubeList }) => {
  const settingInfo = useContext(SettingContext);
  return (
    <div className="space-y-3 bg-gray-50 ">
      {youtubeList?.map((response) => (
        <div
          className={
            response.id === settingInfo.currentYoutube.id
              ? "rounded-xl bg-gray-200  "
              : "rounded-xl bg-gray-100  opacity-70 hover:opacity-100"
          }
          key={response.etag}
        >
          <VideoList detail={response} />
        </div>
      ))}
    </div>
  );
};

export default memo(VideoPlaylist, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
