import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { SettingContext } from "../../../context/SettingContext";
import { XMarkIcon } from "@heroicons/react/24/solid";
import DatePicker from "./DatePicker";
import { months } from "../../common/global";
import DailyReport from "./DailyReport";
import { useQuery } from "@tanstack/react-query";
import {
  getDiffStats,
  getSessionsGroupedByCategoryAndDate,
} from "../../../api/service";
import { AuthContext } from "../../../context/AuthContext";
import Statistic from "./Statistic";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  report: {
    position: "relative",
    margin: "auto",
    height: "100%",
  },
  layerReport: {
    display: "flex",
    position: "relative",
    height: "100%",
    // overflow: "scroll",
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f3f4f6",
    minWidth: 320,
    // maxWidth: 370,
    // overflowX: "hidden",
    // overflow: "auto",
    // maxHeight: "100vh",
  },
  // bg-gray-100 col-span-1 overflow-auto max-w-xs min-h-max"
});
const Report = ({ userSessions, userCategory }) => {
  const settingInfo = useContext(SettingContext);
  const [statisticData, setStatisticData] = useState(userSessions[0]);
  const classes = useStyles();
  const { isLogin } = useContext(AuthContext);
  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        settingInfo.setShowReport(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingInfo]);

  const { isLoading, data } = useQuery(
    ["daily-statistic-data", statisticData.date],
    () => {
      return Promise.all([
        getSessionsGroupedByCategoryAndDate(statisticData.date),
        getDiffStats(statisticData.date),
      ]);
    },
    {
      onSuccess: (dailyStatisticData) => {},
      onError: () => {
        toast.error("Error while fetching data");
      },
      enabled: Boolean(statisticData.data) && Boolean(isLogin),
      staleTime: Number.POSITIVE_INFINITY,
    }
  );
  const [sessionsGroupedByMonth, diffStats] = data ? data : [[], []];
  return (
    <>
      <div className={classes.report} ref={panelRef}>
        <div className={classes.layerReport}>
          <div className={classes.panel} style={{ flex: "0" }}>
            <div
              className={"p-4 cursor-default text-left text-xl font-semibold "}
            >
              Monthly Report
            </div>
            <div className="overflow-auto max-h-screen">
              {userSessions.map((data, index) => (
                <div key={index} onClick={() => setStatisticData(data)}>
                  <DatePicker data={data} />
                </div>
              ))}
            </div>
            {/*Print one year date picker || Print to the oldest year of user data*/}
          </div>
          <div
            className={classes.panel}
            style={{
              minWidth: 480,
              backgroundColor: "white",
              flex: 1,
            }}
          >
            <div
              className={"p-4 text-left font-semibold cursor-default text-xl"}
            >
              {statisticData.date
                ? months[statisticData.date.month]
                : months[userSessions[0].date.month]}{" "}
              <span className="cursor-default font-medium text-gray-700">
                {statisticData.date
                  ? statisticData.date.year
                  : userSessions[0].date.year}
              </span>
            </div>
            <Statistic
              statisticData={statisticData}
              sessionsGroupedByMonth={sessionsGroupedByMonth}
              diffStats={diffStats}
            />
          </div>
          <div
            className={classes.panel}
            style={{
              // maxWidth: 610,
              minWidth: 480,
              backgroundColor: "white",
              flex: 1,
            }}
          >
            <div
              className={
                " cursor-default flex  items-center justify-between font-semibold  text-xl p-4"
              }
              // style={{ maxWidth: 460, minWidth: 460 }}
            >
              <span> Timeline</span>
              <XMarkIcon
                className={"h-6 w-6   hover:text-sky-400 z-10 cursor-pointer"}
                aria-hidden="true"
                onClick={() => settingInfo.setShowReport(false)}
              />
            </div>
            <DailyReport data={statisticData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Report, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
