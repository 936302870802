import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { SettingContext } from "../../context/SettingContext";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  encryptAndStoreData,
  retrieveAndDecryptData,
} from "../common/encryptedStorage";
import { AuthContext } from "../../context/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createUserCategory, deleteUserCategory } from "../../api/service";
import { toast } from "react-toastify";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import { colors } from "../common/global";
import { useUserCategory } from "../hooks/useUserCategory";
const useStyles = makeStyles(() => ({
  containerForm: {
    backgroundColor: "white",
    borderRadius: 8,
    textAlign: "left",
    marginTop: 12,
    boxShadow: "rgb(0 0 0/ 15%) 0px 10px 20px, rgb(0 0 0/10%) 0px 3px 6px",
    animation: "0.1s ease-in-out 0s 1 normal none running expand",
  },
  containerInputForm: {
    padding: "10px 20px 0px",
  },
  inputForm: {
    position: "relative",
    padding: "8px 0px 18px",
  },
  containerValueItem: {
    padding: "8px 0px",
  },
  containerInput: {
    display: "flex",
    justifyContent: "space-between",
  },
  layerInput: {
    width: "100%",
  },
  inputValue: {
    borderRadius: 4,
    fontSize: 22,
    padding: "10px 0px",
    boxShadow: "none",
    border: "none",
    color: "rgb(85,85,85)",
    width: "100%",
    boxSizing: "border-box",
    fontWeight: "bold",
    outline: "none",
  },
  inputPlaceholder: {
    borderRadius: 4,
    fontSize: 22,
    padding: "10px 0px",
    boxShadow: "none",
    border: "none",
    color: "rgb(85,85,85)",
    width: "100%",
    boxSizing: "border-box",
    fontWeight: "bold",
    opacity: 0.5,
    fontStyle: "italic",
    outline: "none",
  },
  containerPomoValue: {
    justifyContent: "space-between",
    display: "flex",
  },
  layerPomo: {
    width: "100%",
  },
  estimatePomo: {
    marginBottom: 8,
  },
  titleEstimation: {
    color: "rgb(85,85,85)",
    fontWeight: "bold",
  },
  unknown: { display: "none" },
  inputPomo: {
    borderRadius: 4,
    backgroundColor: "rgb(239,239,239)",
    fontSize: 16,
    padding: 10,
    boxShadow: "none",
    border: "none",
    color: "rgb(85,85,85)",
    width: 75,
    marginRight: 10,
    fontWeight: "bold",
  },
  buttonUpDown: {
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    opacity: 0.9,
    fontSize: 14,
    padding: "5px 5px",
    display: "inline-block",
    minWidth: 0,
    backgroundColor: "white",
    color: "rgb(85,85,85)",
    border: "1px solid rgb(223,223,223)",
    boxShadow: "rgb(0 0 0/20%) 0px 2px 2px",
    margin: "0px 2px",
  },
  buttonSign: {
    width: 14,
    height: 14,
  },
  containerButtonForm: {
    padding: "14px 20px",
    textAlign: "right",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  cancelButton: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    opacity: 0.9,
    fontSize: 14,
    padding: "8px 12px",
    minWidth: 70,
    display: "inline-block",
    marginRight: 14,
    background: "none",
    border: "none",
    color: "black",
    fontWeight: "bold",
    boxShadow: "none",
  },
  saveButton: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    boxShadow: "rgb(0 0 0/20%) 0px 2px 2px",
    opacity: 0.9,
    backgroundColor: "#bae6fd",
    fontSize: 14,
    padding: "8px 12px",
    minWidth: 70,
    display: "inline-block",
    marginRight: 14,
    background: "none",
    border: "2px solid #bae6fd",
    color: "white",
    fontWeight: "bold",
  },
  saveButtonOn: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    boxShadow: "rgb(0 0 0/20%) 0px 2px 2px",
    opacity: 0.9,
    fontSize: 14,
    fontWeight: 600,
    padding: "8px 12px",
    minWidth: 70,
    display: "inline-block",
    marginRight: 14,
    backgroundColor: "#38bdf8",
    border: "2px solid #38bdf8",
    color: "white",
  },
}));
const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    borderRadius: 8,
    cursor: state.isFocused ? "text" : "pointer",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? "#0ea5e9" : "#111827",
      fontWeight: isSelected ? "500" : "400",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#bae6fd",
      },
    };
  },
};
const createOption = (id, label, color) => ({
  label,
  value: id.toString(),
  color: color,
  id: id.toString(),
  normalizedLabel: convertCategoryNameValue(label),
});

const convertCategoryNameValue = (categoryName) => {
  return categoryName.toLowerCase().replace(/\W/g, "");
};
const storage = retrieveAndDecryptData("categories");
let defaultOptions = storage
  ? storage
  : [
      createOption(1, "Others", "#fb7185"),
      createOption(2, "Working", "#a3e635"),
      createOption(3, "Researching", "#60a5fa"),
      createOption(4, "Studying", "#38bdf8"),
      createOption(5, "Designing", "#818cf8"),
    ];
encryptAndStoreData("categories", defaultOptions);

const EditForm = (props) => {
  console.log(props);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const settingInfo = useContext(SettingContext);
  const [taskName, setTaskName] = useState(props.tasks.taskName);
  const [pomoValue, setPomoValue] = useState(props.tasks.estimatedPomo);
  const [taskDescription, setTaskDescription] = useState(
    props.tasks.taskDescription
  );
  const { user } = useContext(AuthContext);
  /* Refactor select box */
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(props.tasks.category);
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);

  const { userCategories } = useUserCategory();
  const createCategoryMutation = useMutation(
    (newCategory) => {
      return createUserCategory(newCategory);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["user-categories"],
          exact: true,
        });
        setIsAddCategory(true);
        toast.success("Created category successfully!");
      },
      onError: () => {
        toast.error("Failed to create category!");
      },
    }
  );
  const deleteCategoryMutation = useMutation(
    (categoryId) => {
      return deleteUserCategory(categoryId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["user-categories"],
          exact: true,
        });
        setIsDeleteCategory(true);
        toast.success("Deleted category successfully!");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
  // Ensure the options are set according to the user's login status.
  useEffect(() => {
    // If user is logged in and user categories are available,
    // format them to match react-select's expected object shape.
    if (user && userCategories) {
      const formattedUserCategories = userCategories.map((category) => ({
        label: category.name,
        value: category._id,
        id: category._id,
        normalizedLabel: convertCategoryNameValue(category.name),
        color: category.color,
      }));
      setOptions(formattedUserCategories);
      // If categoryValue is not set or does not exist in updated options,
      // set it to the first option from the fetched user categories.
      if (
        !categoryValue ||
        !formattedUserCategories.find(
          (c) => c.normalizedLabel === categoryValue.normalizedLabel
        )
      ) {
        setCategoryValue(formattedUserCategories[0]);
      }
    } else {
      // User is not logged in, use defaultOptions
      setOptions(defaultOptions);
      setCategoryValue(defaultOptions[0]);
    }
  }, [user, userCategories]);

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props} className={"!flex items-center hover:bg-sky-100"}>
      <span>{props.data.label}</span>
      <XMarkIcon
        onClick={() => handleDeleteCategory(props.data)}
        className={
          JSON.stringify(props.data) === JSON.stringify(categoryValue) ||
          "break" === props.data.normalizedLabel
            ? "hidden"
            : "z-10 absolute right-0 pr-3 h-8 w-8"
        }
      />
    </Option>
  );
  const NoOptionMessage = (props) => (
    <Option {...props} className={"!flex items-center hover:bg-sky-100"}>
      <span>You cannot have more than 10 categories</span>
    </Option>
  );
  const handleCreateCategory = (categoryName) => {
    setIsLoading(true);
    setTimeout(() => {
      let getColorIndex;
      let getColor;
      let isContainedColor;
      let checkingColor = true;
      while (checkingColor) {
        // avoiding user to create more than 10 options
        getColorIndex = Math.floor(Math.random() * colors.length);
        getColor = colors[getColorIndex];
        isContainedColor = options.some(
          (category) =>
            JSON.stringify(category.color) === JSON.stringify(getColor)
        );
        if (isContainedColor) {
          checkingColor = true;
        } else {
          checkingColor = false;
        }
      }
      const id = uuidv4();
      const newOption = createOption(id, categoryName, getColor);

      if (user) {
        createCategoryMutation.mutate({ name: categoryName, color: getColor });
      }
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setCategoryValue(newOption);
    }, 500);
  };
  const handleDeleteCategory = async (category) => {
    if (user) {
      deleteCategoryMutation.mutate(category.id);
    } else {
      defaultOptions = defaultOptions.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(category)
      );

      setOptions(defaultOptions);
    }
  };

  useEffect(() => {
    if (isDeleteCategory) {
      setCategoryValue(defaultOptions[0]);
      encryptAndStoreData("categories", options);
      setIsDeleteCategory(false);
    } else if (isAddCategory) {
      encryptAndStoreData("categories", options);
      setIsAddCategory(false);
    }
  }, [isDeleteCategory, isAddCategory]);
  // /* End refactor select box */
  const checkDisable = () => {
    if (taskName === "" || pomoValue <= 0) {
      return true;
    }
    return false;
  };
  const handleDecrement = () => {
    setPomoValue((value) => (value - 1 > 1 ? value - 1 : 1));
  };

  const handleIncrement = () => {
    setPomoValue((value) => value + 1);
  };

  const handleCancelInput = () => {
    props.setShowEditForm((prev) => !prev);
    props.tasks.completeEdit = true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      ...props.tasks,
      taskName: taskName,
      taskDescription: taskDescription,
      category: categoryValue,
      estimatedPomo: pomoValue,
      completeEdit: true,
    });
    props.setShowEditForm((prev) => !prev);
  };

  return (
    <>
      <div className={classes.containerForm}>
        <div className={classes.containerInputForm}>
          <div className={classes.inputForm}>
            <div className={classes.containerValueItem}>
              {/* <div className={classes.containerInput}>
              <div className={classes.layerInput}>
                <input
                  autoFocus={true}
                  type="text"
                  defaultValue={taskName}
                  onInput={(e) => settaskName(e.target.value)}
                  placeholder="What are you working on?"
                  className={
                   taskName 
                      ? classestaskName.
                      : classes.inputPlaceholder
                  }
                />
              </div>
            </div> */}
              <div>
                <label
                  htmlFor="taskName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Task name
                </label>
                <input
                  type="text"
                  id="taskName"
                  autoFocus={true}
                  defaultValue={taskName}
                  onInput={(e) => setTaskName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-2 focus:ring-sky-300 block w-full p-2.5 "
                  placeholder="What are your focus?"
                  required
                />

                <label
                  htmlFor="description"
                  className="block mb-2 mt-2 text-sm font-medium text-gray-900 "
                >
                  Task description
                </label>
                <textarea
                  onInput={(e) => setTaskDescription(e.target.value)}
                  defaultValue={taskDescription}
                  id="description"
                  className="block p-2.5 w-full text-sm resize-none h-20 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none focus:ring-2 focus:ring-sky-300 "
                  placeholder="Task description"
                ></textarea>
              </div>
            </div>
            <div className={classes.containerValueItem}>
              <div className="flex justify-between items-center space-x-4 ">
                <div className="w-1/2">
                  <span className="font-medium text-sm">Category</span>
                  <CreatableSelect
                    className="mt-1 rounded-lg w-full relative cursor-pointer bg-white text-left border border-gray-300"
                    styles={style}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={(category) => setCategoryValue(category)}
                    onCreateOption={handleCreateCategory}
                    options={options}
                    defaultValue={categoryValue}
                    components={{
                      Option: IconOption,
                    }}
                    value={categoryValue}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary25: "#e0f2fe",
                        primary: "#e0f2fe",
                      },
                    })}
                  />
                </div>
                <div className="w-1/2 ">
                  <span className="text-sm font-medium cursor-default w-full">
                    Estimate Pomodoros
                    <div className={classes.unknown}>Act</div>
                  </span>
                  <div className="flex mt-1 items-center">
                    <input
                      className="rounded-lg mr-2 px-3 h-10 text-left bg-white focus:outline-none border border-gray-300"
                      value={pomoValue}
                      onInput={(event) =>
                        setPomoValue(parseInt(event.target.value))
                      }
                      min={1}
                      type="number"
                    />
                    <button
                      // className={classes.buttonUpDown}
                      className="rounded-lg mr-0.5 px-2 py-0.5 h-10 text-left bg-white focus:outline-none border border-gray-300"
                      onClick={handleIncrement}
                    >
                      <ArrowUpIcon className={classes.buttonSign} />
                    </button>
                    <button
                      className="rounded-lg px-2 py-0.5 h-10 text-left bg-white focus:outline-none border border-gray-300"
                      // className={classes.buttonUpDown}
                      onClick={handleDecrement}
                    >
                      <ArrowDownIcon className={classes.buttonSign} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className={classes.containerValueItem}></div> this use for Add note and Add project in future update*/}
          </div>
        </div>
        <div className={classes.containerButtonForm}>
          <button
            onClick={() => props.deleteTask(props.tasks.id)}
            className="font-semibold text-md text-center justify-center items-center text-sky-400 "
          >
            Delete
          </button>
          <div>
            <button
              className={classes.cancelButton}
              onClick={handleCancelInput}
            >
              Cancel
            </button>
            <button
              className={
                taskName
                  ? pomoValue > 0
                    ? classes.saveButtonOn
                    : classes.saveButton
                  : classes.saveButton
              }
              disabled={checkDisable()}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditForm;
