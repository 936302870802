import React from "react";
import { Divider } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
const AccountSetting = () => {
  const { user } = useContext(AuthContext);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <div>
        <div className="items-center text-center flex">
          <span className="text-xl font-semibold text-sky-400 m-auto cursor-default">
            Account Information
          </span>
        </div>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      </div>
      <div className="p-2 overflow-auto flex flex-col h-full cursor-default space-y-2">
        <div className="">
          <span className="text-lg flex font-semibold cursor-default">
            Profile
          </span>
          <div className="flex flex-row space-x-4">
            <div className="w-1/2 space-y-2">
              <div className="flex text-left font-medium justify-between">
                <div className="text-sm flex flex-col w-full">
                  <span className="font-semibold">Username</span>
                  <div
                    className={`bg-gray-50 border-[1px] border-gray-300 border-solid w-full p-1.5 rounded-lg ${
                      !user && "blur-[1.5px] cursor-not-allowed"
                    } }`}
                  >
                    <span>
                      {user ? user.username : "You have not logged in"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex text-left font-medium justify-between">
                <div className="text-sm flex flex-col w-full">
                  <span className="font-semibold">Email</span>
                  <div
                    className={`bg-gray-50 border-[1px] border-gray-300 border-solid w-full p-1.5 rounded-lg ${
                      !user && "blur-[1.5px] cursor-not-allowed"
                    } }`}
                  >
                    <span className="">
                      {user ? user.email : "You have not logged in"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex text-left font-medium justify-between">
                <div className="flex flex-col w-full">
                  <span className="font-semibold">Subscription</span>
                  <div className="bg-sky-400 text-white w-fit p-2 text-sm rounded-xl brightness-110">
                    {user && user.subscription ? (
                      <span className="font-semibold">
                        {capitalizeFirstLetter(user && user.subscription)}
                      </span>
                    ) : (
                      <span className="font-semibold">Standard</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex text-left font-medium justify-between">
              <div className="w-full h-full">
                {user && (
                  <img
                    src={user.picture}
                    referrerPolicy="no-referrer"
                    className="rounded-full w-28 h-28"
                    alt="User profile"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm font-semibold space-y-2">
          <span className="text-lg flex cursor-default">Linked Accounts</span>
          <div className="flex text-left justify-between">
            {user && user.googleAccount ? (
              <div className="bg-gray-100 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-default text-zinc-800 hover:text-black">
                <span>Connected with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  ></path>
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  ></path>
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="bg-gray-50 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-pointer text-zinc-800 hover:bg-gray-100 hover:text-black">
                <span>Connect with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  ></path>
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  ></path>
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
          <div className="flex text-left justify-between">
            {user && user.facebookAccount ? (
              <div className="bg-gray-100 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-default text-zinc-800 ">
                <span>Connected with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#039be5"
                    d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="bg-gray-50 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-pointer text-zinc-800 hover:bg-gray-100 hover:text-black">
                <span>Connect with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#039be5"
                    d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
          <div className="flex text-left justify-between">
            {user && user.linkedinAccount ? (
              <div className="bg-gray-100 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-default text-zinc-800 ">
                <span>Connected with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="bg-gray-50 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-pointer text-zinc-800 hover:bg-gray-100 hover:text-black">
                <span>Connect with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </div>
            )}
          </div>
          <div className="flex text-left justify-between">
            {user && user.twitterAccount ? (
              <div className="bg-gray-100 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-default text-zinc-800 ">
                <span>Connected with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#212121"
                    fillRule="evenodd"
                    d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#fff"
                    d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"
                  ></path>
                  <polygon
                    fill="#fff"
                    points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"
                  ></polygon>
                  <polygon
                    fill="#fff"
                    points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"
                  ></polygon>
                </svg>
              </div>
            ) : (
              <div className="bg-gray-50 p-2 rounded-xl flex items-center justify-center space-x-1 cursor-pointer text-zinc-800 hover:bg-gray-100 hover:text-black">
                <span>Connect with</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#212121"
                    fillRule="evenodd"
                    d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#fff"
                    d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"
                  ></path>
                  <polygon
                    fill="#fff"
                    points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"
                  ></polygon>
                  <polygon
                    fill="#fff"
                    points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"
                  ></polygon>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetting;
