import { http, openAI } from "./config";
import axios from "axios";
import OpenAI from "openai";
export const getUserProfile = async () => {
  try {
    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
    const timeZoneOffsetMilliseconds = timeZoneOffsetMinutes * 60 * 1000;
    const validMinOffset = -720 * 60 * 1000;
    const validMaxOffset = 720 * 60 * 1000;

    const safeTimeZoneOffset = Math.min(
      Math.max(timeZoneOffsetMilliseconds, validMinOffset),
      validMaxOffset
    );
    const body = { timeZoneOffset: safeTimeZoneOffset };
    const response = await http.post(`/auth/login`, body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPDFList = async () => {
  try {
    const response = await http.get("/ai/pdf");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPDFById = async (id) => {
  try {
    const response = await http.get(`/ai/pdf/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await http.post("/ai/pdf", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const deletePDF = async (id) => {
  try {
    const response = await http.delete(`/ai/pdf/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const chatWithPDFAI = async (query, documentId) => {
  try {
    const response = await http.post("/ai/pdfChat", { query, documentId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const sendMessageToAI = async (messages, apiType) => {
  try {
    if (apiType === "user") {
      const apiKey = localStorage.getItem("apiKey");
      if (!apiKey) return null;
      const openAI = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true,
      });
      const completion = await openAI.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You are a helpful assistant. Your responsible is to help me answer any questions that I asked clearly and logically",
          },
          ...messages,
        ],
        model: "gpt-4o-mini",
        max_tokens: 4096,
      });

      return completion;
    }
    if (apiType === "server") {
      const response = await http.post("/chat", { messages });
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const generateTitle = async (messages, apiType) => {
  try {
    messages = [
      ...messages,
      {
        role: "user",
        content:
          "What would be a short and relevant title for this chat? You must strictly answer with only the title, no other text is allowed. Answer in English.",
      },
    ];
    if (apiType === "server") {
      const response = await http.post("/chat", { messages });
      return response.data;
    }
    if (apiType === "user") {
      const apiKey = localStorage.getItem("apiKey");
      if (!apiKey) return null;
      const openAI = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true,
      });
      const completion = await openAI.chat.completions.create({
        messages,
        model: "gpt-4o-mini",
      });
      return completion;
    }
  } catch (e) {
    return null;
  }
};

export const logOut = async () => {
  const response = await http.get(`/auth/logout`);
  return response.data;
};
export const getAllUserSessions = async () => {
  try {
    const response = await http.get("/sessions/all");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const createUserSession = async (userSession) => {
  try {
    const response = await http.post("/sessions", userSession);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const createUserBreakSession = async (userBreakSession) => {
  try {
    const response = await http.post(
      "/sessions/breakSession",
      userBreakSession
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
export const getSessionsGroupedByMonth = async () => {
  try {
    const response = await http.get("/sessions/getSessionsGroupedByMonth");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getSessionsGroupedByCategoryAndDate = async (date) => {
  try {
    const response = await http.get(
      "/sessions/getSessionsGroupedByCategoryAndDate",
      {
        params: {
          year: date.year,
          month: date.month,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getDiffStats = async (date) => {
  try {
    const response = await http.get("/sessions/diffStatsBetweenMonths", {
      params: {
        year: date.year,
        month: date.month,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUserCategory = async () => {
  try {
    const response = await http.get("/categories", {});
    const breakCategory = await http.get("/categories/getBreak");
    response.data.push(breakCategory.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createUserCategory = async (category) => {
  try {
    const response = await http.post("/categories", category);
    return response;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const activateLicenseKey = async (licenseKey) => {
  try {
    console.log("license", licenseKey);
    const response = await http.post(
      "/payment/lemon/activateLicense",
      licenseKey
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteUserCategory = async (categoryId) => {
  try {
    const response = await http.delete(`/categories/${categoryId}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getVideoYoutube = async (keyword) => {
  const response = await axios.get(
    "https://www.googleapis.com/youtube/v3/search",
    {
      params: {
        q: keyword,
        part: "snippet",
        type: "video",
        maxResults: 10,
        key: process.env.REACT_APP_YOUTUBE_KEY,
      },
    }
  );
  return response.data;
};

export const getDefaultVideo = async () => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/youtube/v3/videos",
      {
        params: {
          part: "snippet",
          id: "jfKfPfyJRdk",
          key: process.env.REACT_APP_YOUTUBE_KEY,
        },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getDefaultChannel = async () => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/youtube/v3/channels",
      {
        params: {
          id: "UCSJ4gkVC6NrvII8umztf0Ow",
          part: "snippet, statistics",
          key: process.env.REACT_APP_YOUTUBE_KEY,
        },
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getChannelDetail = async (channelId) => {
  const response = await axios.get(
    "https://www.googleapis.com/youtube/v3/channels",
    {
      params: {
        id: channelId,
        part: "snippet, statistics",
        key: process.env.REACT_APP_YOUTUBE_KEY,
      },
    }
  );
  return response.data;
};
