const YoutubeButton = (props) => {
  return (
    <div>
      <img
        className={
          props.playNow
            ? "animate-spin-slow rounded-full h-12 w-12 "
            : "rounded-full h-12 w-12"
        }
        style={{ WebkitUserDrag: "none" }}
        alt="channel"
        src={props.thumbnail}
      />
    </div>
  );
};

export default YoutubeButton;
